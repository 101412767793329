import Resizer from 'react-image-file-resizer';

export class ImageCropUtils {
  constructor(image, imageContainerDimensions, minCropHeight, aspectRatio) {
    this.image = image;
    this.imageBlob = null;
    this.originalImageDimensions = { height: 0, width: 0 };
    this.aspectRatio = aspectRatio;
    this.imageContainerDimensions = imageContainerDimensions;
    this.minCropHeight = minCropHeight;
    this.imageDimensionsInDOM = { height: 0, width: 0 };
    this.cropperInfo = { height: 0, topOffset: 0, leftOffset: 0 };
    this.sizeOfContainerPixel = 0;
  }

  getOriginalImageDimensions() {return this.originalImageDimensions;}
  getImageDimensionsInDom() {return this.imageDimensionsInDOM;}
  getCropperInfo() {return this.cropperInfo;}
  getImage() {return this.image;}

  async setDimensions() {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          // set the imageBlob
          this.imageBlob = image;
          // define image height and width
          let imageHeight = image.height;
          let imageWidth = image.width;
          // set image dimensions in pixels
          this.originalImageDimensions = { height: imageHeight, width: imageWidth };
          // calculate image occupied width in DOM
          const imageAspectRatio = imageWidth/imageHeight;
          const imageWidthInDOM = imageAspectRatio * this.imageContainerDimensions.height;
          // calculate size of container pixel
          let sizeOfContainerPixel;
          if (imageWidthInDOM < this.imageContainerDimensions.width) {
            sizeOfContainerPixel = this.imageContainerDimensions.height / imageHeight;
            // update the image dimensions in dom
            this.imageDimensionsInDOM = {
              height: this.imageContainerDimensions.height,
              width: sizeOfContainerPixel * imageWidth
            };
          } else {
            sizeOfContainerPixel = this.imageContainerDimensions.width / imageWidth;
            // update the image dimensions in dom
            this.imageDimensionsInDOM = {
              height: sizeOfContainerPixel * imageHeight,
              width: this.imageContainerDimensions.width
            };
          }
          // set size of container pixel
          this.sizeOfContainerPixel = sizeOfContainerPixel;
          const cropperDimensionWrtHeight = {
            height: sizeOfContainerPixel * imageHeight,
            width: (sizeOfContainerPixel * imageHeight) * this.aspectRatio
          };
          const cropperDimensionWrtWidth = {
            height: (sizeOfContainerPixel * imageWidth) * (1/this.aspectRatio),
            width: sizeOfContainerPixel * imageWidth
          };
          let cropperHeight = 0;
          // update the image cropper height
          if (cropperDimensionWrtHeight.height <= this.imageDimensionsInDOM.height &&
            cropperDimensionWrtHeight.width <= this.imageDimensionsInDOM.width
          ) {cropperHeight = cropperDimensionWrtHeight.height;}
          else if (cropperDimensionWrtWidth.height <= this.imageDimensionsInDOM.height &&
            cropperDimensionWrtWidth.width <= this.imageDimensionsInDOM.width
          ) {cropperHeight = cropperDimensionWrtWidth.height;}
          // update the minimum image crop limit
          this.minCropHeight = Math.ceil(sizeOfContainerPixel * this.minCropHeight);
          // update the cropper info
          this.cropperInfo = {
            height: cropperHeight,
            topOffset: (this.imageContainerDimensions.height - this.imageDimensionsInDOM.height)/2,
            leftOffset: (this.imageContainerDimensions.width - this.imageDimensionsInDOM.width)/2
          };
          // resolve the method
          resolve();
        };
      };
      reader.readAsDataURL(this.image);
    });
  }

  async cropImage() {
    return new Promise((resolve) => {
      // calculate top offset
      const imageExtraTopOffset = (this.imageContainerDimensions.height - this.imageDimensionsInDOM.height) / 2;
      const actualTopOffset = this.cropperInfo.topOffset - imageExtraTopOffset;
      const topOffset = actualTopOffset * (this.originalImageDimensions.height / this.imageContainerDimensions.height);

      // calculate left offset
      const imageExtraLeftOffset = (this.imageContainerDimensions.width - this.imageDimensionsInDOM.width) / 2;
      const actualLeftOffset = this.cropperInfo.leftOffset - imageExtraLeftOffset;
      const leftOffset = actualLeftOffset * (this.originalImageDimensions.width / this.imageContainerDimensions.width);

      // calculate cropped image width and height
      const cropHeight = (this.imageDimensionsInDOM.width < this.imageContainerDimensions.width)
        ? this.cropperInfo.height * (1/this.sizeOfContainerPixel)
        : this.cropperInfo.height * (1/this.sizeOfContainerPixel);
      const cropWidth = cropHeight * this.aspectRatio;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.drawImage(
        this.imageBlob,
        leftOffset, topOffset,
        cropWidth, cropHeight,
        0, 0,
        cropWidth, cropHeight
      );

      canvas.toBlob((croppedBlob) => {
        // Create a File object from the Blob
        const croppedFile = new File(
          [croppedBlob], 'cropped-image.png', { type: 'image/png' }
        );
        this.image = croppedFile;
        this.imageBlob = croppedBlob;
        resolve(croppedFile);
      }, 'image/png');
    });
  }

  async resizeImage(height, width) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        this.imageBlob,
        width, height,
        'PNG',
        100,
        0,
        (resizedBlob) => {
          // Create a File object from the Blob
          const resizedFile = new File(
            [resizedBlob], 'resized-image.png', { type: 'image/png' }
          );
          this.image = resizedFile;
          this.imageBlob = resizedBlob;
          resolve(resizedFile);
        },
        'blob'
      );
    });
  }

  manipulateCropSquareFrame(movementX, movementY, dragOperation, offsets) {
    const {
      topOffset, rightOffset, bottomOffset, leftOffset
    } = offsets;

    const isImageCroppable = (Math.floor(this.cropperInfo.height) > this.minCropHeight);

    if (movementX > 0 && movementY > 0) {
      // top-left movement
      if (dragOperation === 'resize-top-left' && isImageCroppable) {
        // contract the container
        this.cropperInfo = {
          height: this.cropperInfo.height - 1,
          topOffset: this.cropperInfo.topOffset + 1,
          leftOffset: this.cropperInfo.leftOffset + 1
        }
      } else if (dragOperation === 'resize-bottom-right' && rightOffset < 0 && bottomOffset < 0) {
        // expand the container
        this.cropperInfo = {
          ...this.cropperInfo,
          height: this.cropperInfo.height + 1
        }
      }
    } else if (movementX < 0 && movementY > 0) {
      // top-right movement
      if (dragOperation === 'resize-top-right' && isImageCroppable) {
        // contract the container
        this.cropperInfo = {
          ...this.cropperInfo,
          height: this.cropperInfo.height - 1,
          topOffset: this.cropperInfo.topOffset + 1
        }
      } else if (dragOperation === 'resize-bottom-left' && bottomOffset < 0 && leftOffset > 0) {
        // expand the container
        this.cropperInfo = {
          ...this.cropperInfo,
          height: this.cropperInfo.height + 1,
          leftOffset: this.cropperInfo.leftOffset - 1
        }
      }
    } else if (movementX > 0 && movementY < 0) {
      // bottom-left movement
      if (dragOperation === 'resize-bottom-left' && isImageCroppable) {
        // contract the container
        this.cropperInfo = {
          ...this.cropperInfo,
          height: this.cropperInfo.height - 1,
          leftOffset:this.cropperInfo.leftOffset + 1
        }
      } else if (dragOperation === 'resize-top-right' && topOffset > 0 && rightOffset < 0) {
        // expand the container
        this.cropperInfo = {
          ...this.cropperInfo,
          height: this.cropperInfo.height + 1,
          topOffset: this.cropperInfo.topOffset - 1
        }
      }
    } else if (movementX < 0 && movementY < 0) {
      // bottom-right movement
      if (dragOperation === 'resize-bottom-right' && isImageCroppable) {
        // contract the container
        this.cropperInfo = {
          ...this.cropperInfo,
          height: this.cropperInfo.height - 1
        }
      } else if (dragOperation === 'resize-top-left' && topOffset > 0 && leftOffset > 0) {
        // expand the container
        this.cropperInfo = {
          height: this.cropperInfo.height + 1,
          topOffset: this.cropperInfo.topOffset - 1,
          leftOffset: this.cropperInfo.leftOffset - 1
        }
      }
    } else if (dragOperation === 'shift-position') {
      if (movementY < 0 && topOffset > 0) {
        this.cropperInfo = { ...this.cropperInfo, topOffset:  this.cropperInfo.topOffset - 1 }
      } else if (movementX > 0 && rightOffset < 0) {
        this.cropperInfo = { ...this.cropperInfo, leftOffset: this.cropperInfo.leftOffset + 1 }
      } else if (movementY > 0 && bottomOffset < 0) {
        this.cropperInfo = { ...this.cropperInfo, topOffset: this.cropperInfo.topOffset + 1 }
      } else if (movementX < 0 && leftOffset > 0) {
        this.cropperInfo = { ...this.cropperInfo, leftOffset: this.cropperInfo.leftOffset - 1 }
      }
    }
  }
}