import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './pages';
import {
  NotificationProvider, AuthProvider
} from './providers';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <NotificationProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </NotificationProvider>
  </React.StrictMode>
);
