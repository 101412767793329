import { useState } from 'react';
import '../styles/components/GoogleVerifier.scss';
import { Loader } from './index';
import { authenticateWithGoogle } from '../utils';
import googleIcon from '../assets/register/google-icon.svg';

export default function GoogleVerifier(props) {
  const { onGoogleTokenChange, authType } = props;
  const [
    isSignUpWithGoogleButtonClicked, setIsSignUpWithGoogleButtonClicked
  ] = useState(false);

  const handleSignUpWithGoogleButtonClick = async () => {
    setIsSignUpWithGoogleButtonClicked(true);
    const googleAuthResponse = await authenticateWithGoogle();
    if (googleAuthResponse.success) {
      onGoogleTokenChange(googleAuthResponse.elevated_access_token);
    }
    setIsSignUpWithGoogleButtonClicked(false);
  }

  return (
    <div className="google-verifier">
      <button
        onClick={handleSignUpWithGoogleButtonClick}
        disabled={isSignUpWithGoogleButtonClicked}
      >
        {isSignUpWithGoogleButtonClicked ? (
          <>
            <Loader height="60%" color="dodgerblue" />
            <p>
              Signing {(authType === "sign-in") ? "in" : "up"} with Google...
            </p>
          </>
        ) : (
          <>
            <img src={googleIcon} alt="google-icon"/>
            <p>
              Sign {(authType === "sign-in") ? "in" : "up"} with Google
            </p>
          </>
        )}
      </button>
    </div>
  );
}
