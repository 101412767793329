import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faStore, faAlignLeft, faIcons, faCircleXmark
} from '@fortawesome/free-solid-svg-icons';
import '../../styles/Register/InputShopDetails.scss';
import { useRegister } from '../../hooks';
import {
  CustomInput, CustomDropdown, CustomTextArea
} from '../../components';
import inputShopDetailsImg from '../../assets/register/input-shop-details.png';

export default function InputShopDetails() {
  // define useRegister custom hook
  const register = useRegister();

  // get the required states and functions from useRegister custom hook
  const {
    shopOwnerName, handleShopOwnerNameChange,
    shopName, handleShopNameChange,
    availableCategoriesList, shopSubCategoryInputOption,
    shopCategory, handleSelectShopCategoryChange,
    shopSubCategory, handleSelectShopSubCategoryDropdownChange,
    handleSelectShopSubCategoryTextInputChange, resetShopCategoryInfo,
    aboutShop, handleAboutShopChange
  } = register;

  // return the jsx
  return (
    <div className="input-shop-details">

      {/*define inputs*/}
      <div className="inputs">

        {/*define shop name input*/}
        <div className="shop-name-input-container">
          <div className="indicator"></div>
          <input
            className="shop-name-input"
            type="text"
            value={shopName}
            onChange={handleShopNameChange}
            maxLength={50}
            placeholder="Shop Name"
          />
          <div className="icon-container">
            <FontAwesomeIcon icon={faStore} className="icon" />
          </div>
        </div>

        {/*define shop owner name input*/}
        <div className="owner-name-input">
          <CustomInput
            type="text"
            value={shopOwnerName}
            onChange={handleShopOwnerNameChange}
            placeholder="Shop Owner's Name"
            maxLength={30}
            icon={faUser}
            required
          />
        </div>

        <div className='shop-category-inputs-container'>
          <div className='progress-bars'>
            <div
              className='progress-bar'
              style={{ backgroundColor: shopCategory ? "seagreen" : null }}
            ></div>
            <div
              className='progress-bar'
              style={{
                backgroundColor: (shopCategory && shopSubCategory)
                  ? "seagreen" : null
              }}
            ></div>
          </div>

          <div className='shop-category-inputs'>
            {shopCategory && (
              <div className='back-button'>
                <FontAwesomeIcon
                  className='icon'
                  icon={faCircleXmark}
                  onClick={resetShopCategoryInfo}
                />
              </div>
            )}

            {shopCategory ? (
              <div className='shop-category-input'>
                {/*define shop subcategory input*/}
                {shopSubCategoryInputOption === "text" ? (
                  <CustomInput
                    type="text"
                    value={shopSubCategory}
                    onChange={handleSelectShopSubCategoryTextInputChange}
                    placeholder="2. Mention Shop's SubCategory"
                    maxLength={50}
                    icon={faStore}
                    required
                  />
                ) : (
                  <CustomDropdown
                    value={shopSubCategory}
                    onSelect={handleSelectShopSubCategoryDropdownChange}
                    placeholder='2. Select Shop SubCategory'
                    dropdownList={availableCategoriesList[shopCategory]?.values}
                    icon={faStore}
                    required
                  />
                )}
              </div>
            ) : (
              <div className='shop-category-input'>
                {/*define shop category input*/}
                <CustomDropdown
                  value={shopCategory}
                  onSelect={handleSelectShopCategoryChange}
                  placeholder='1. Select Shop Category'
                  dropdownList={Object.keys(availableCategoriesList)}
                  icon={faIcons}
                  required
                />
              </div>
            )}
          </div>
        </div>

        {/*define shop description input*/}
        <div className='shop-description-input'>
          <CustomTextArea
            value={aboutShop}
            onChange={handleAboutShopChange}
            placeholder='About Your Shop'
            maxLength={200}
            icon={faAlignLeft}
          />
        </div>
      </div>

      {/*right side image*/}
      <div className="preview-image">
        <img
          src={inputShopDetailsImg}
          alt="preview-image"
        />
      </div>
    </div>
  );
}
