import '../styles/components/Loader.scss';

export default function Loader(props) {
  const { height, color} = props

  return (
    <div
      className="loader"
      style={{
        height,
        borderTopColor: color
      }}
    ></div>
  );
}
