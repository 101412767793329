import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/Dashboard/ManageInventory/AddEditItem/SelectVariantsPanel.scss';
import { isNumber } from '../../../../utils';
import SelectVariantPricePanel from './SelectVariantPricePanel';

export default function SelectVariantsPanel(props) {
  const { variants, setVariants } = props;
  const [variantPriceIndex, setVariantPriceIndex] = useState(null);

  const handleVariantNameChange = (index, variantName) => {
    const newVariants = [...variants];
    newVariants[index].variantName = variantName.replace(/^\s+/g, '');
    setVariants(newVariants);
  };

  const handleSetVariantPrice = (index, variantPrice) => {
    const newVariants = [...variants];
    newVariants[index].variantPrice = variantPrice;
    // check if there is any empty variant-name and field empty
    // if empty: do not create a new empty variant input field
    // else: create a new empty input field
    let EmptyInputExists = false;
    for (let variant of newVariants){
      if (variant.variantName === '' || variant.variantPrice === ''){
        EmptyInputExists = true;
        break;
      }
    }
    if (!EmptyInputExists) {
      // append new variant input box
      newVariants.unshift({ variantName: '', variantPrice: '' });
      setVariants(newVariants);
    }

    setVariantPriceIndex(null);
  };

  const deleteVariant = (index) => {
    if (variants.length > 1) {
      const newVariants = [...variants];
      newVariants.splice(index, 1);
      // set the variant-name to 'default' if only on variant left
      if (newVariants.length === 1){
        newVariants[0].variantName = "default";
      }
      setVariants(newVariants);
    }
  };

  const toggleSetPrice = (index) => {
    if (variants[index].variantName) {
      setVariantPriceIndex(isNumber(variantPriceIndex) ? null : index);
    }
  }

  return (
    <div className="select-variants-panel">
      <div className="variants-form-container">
        {variants.map((variant, index) => (
          <form
            key={`variant-form-${index}`}
            className="add-new-variant-form"
            onSubmit={(event) => event.preventDefault()}
          >
            <input
              className="variant-input"
              type="text"
              placeholder="Add Variant"
              value={variants[index].variantName}
              onChange={(event) => handleVariantNameChange(index, event.target.value)}
              maxLength={30}
              required
            />

            <p className="colon">:</p>

            <button
              className="variant-btn"
              onClick={() => toggleSetPrice(index)}
              style={{color: variants[index].variantPrice ? 'seagreen' : null}}
            >
              {variants[index].variantPrice ? variants[index].variantPrice : 'Set Price'}
            </button>

            {(index) ? (
              <div className="delete-icon-container">
                <FontAwesomeIcon
                  className="delete-icon"
                  icon={faTrashCan}
                  onClick={() => deleteVariant(index)}
                />
              </div>
              ) : (<div className="delete-icon-container"></div>)
            }
          </form>
        ))}

        {isNumber(variantPriceIndex) && (
          <div className="select-price-panel-container">
            <SelectVariantPricePanel
              removePopUpFunction={() => toggleSetPrice(variantPriceIndex)}
              price={variants[variantPriceIndex].variantPrice}
              setPrice={(price) => handleSetVariantPrice(variantPriceIndex, price)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
