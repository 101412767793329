import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Register/index.scss';
import { useAuth, useRegister } from '../../hooks';
import { VentoryLogo } from '../../components';
import InputContainer from './InputContainer';
import VerifyCredentials from './VerifyCredentials';
import InputShopDetails from './InputShopDetails';
import InputShopImages from './InputShopImages';
import InputContactDetails from './InputContactDetails';

export default function Register() {
  const auth = useAuth();

  const register = useRegister();
  const {
    stepsToRegisterInfo, currentStepNumber,
    goBackToPrevStep, moveToNextStep,
    isLoading, handleLogout
  } = register;

  return (
    <div className="register">
      <nav className="register-nav">
        <VentoryLogo />

        {auth.user && (
          <button className="logout-btn" onClick={handleLogout}>
            <p>Logout</p>
            <FontAwesomeIcon icon={faPowerOff} className="icon" />
          </button>
        )}
      </nav>

      <div className="register-container">
        <InputContainer
          stepsToRegisterInfo={stepsToRegisterInfo}
          currentStepNumber={currentStepNumber}
          prevStepButtonOnClick={goBackToPrevStep}
          nextStepButtonOnClick={moveToNextStep}
          isLoading={isLoading}
        >
          { currentStepNumber === 1 ? (<InputShopDetails />)
          : currentStepNumber === 2 ? (<InputShopImages />)
          : currentStepNumber === 3 ? (<InputContactDetails />)
          : currentStepNumber === 4 ? (<div> </div>)
          : currentStepNumber === 5 ? (<div> </div>)
          : (<VerifyCredentials />)}
        </InputContainer>
      </div>
    </div>
  );
}
