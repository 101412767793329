import { useContext, useEffect, useState } from 'react';
import { db } from '../configs';
import { DashboardContext } from '../providers';
import { useAuth } from '../hooks';

export const useDashboard = () => {
  return useContext(DashboardContext);
};

export const useProvideDashboard = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [inventory, setInventory] = useState({order: []});
  const inventoryList = Object.keys(auth.user.inventory);
  const [inventoryId, setInventoryId] = useState('');

  useEffect(() => {
    if (inventoryId) {
      db
        .collection('inventory')
        .doc(inventoryId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setInventory(doc.data());
          }
        });
      setIsLoading(false);
    }
  }, [inventoryId]);

  const changeInventory = (inventoryId) => {
    // set the new Inventory id
    setInventoryId(inventoryId);
  };

  return {
    isLoading,
    inventoryList,
    inventoryId,
    inventory,
    changeInventory
  };
};
