import { useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { AuthContext } from '../providers';
import { useNotification } from './useProvideNotification';
import {
  authenticate as requestAuthentication,
  changeInventory as requestChangeInventory,
  checkEmail as requestCheckEmail,
  checkMobile as requestCheckMobile
} from '../api';
import {
  getRefreshTokenFromLocalStorage,
  setAccessTokenInLocalStorage,
  setRefreshTokenInLocalStorage,
  clearLocalStorage
} from '../utils';

export const useAuth = () => {
  return useContext(AuthContext);
}

export const useProvideAuth = () => {
  const [user, setUser] = useState(null);
  const [inventoryId, setInventoryId] = useState('');
  const [loading, setLoading] = useState(true);
  const [reCaptchaContainerId, setReCaptchaContainerId] = useState('');
  const notification = useNotification();

  useEffect(() => {
    // get the refresh token from local storage
    getRefreshTokenFromLocalStorage().then((refresh_token) => {
      // get server access token in exchange for refresh token
      requestAuthentication(refresh_token)
        .then((authResponse) => {
          if (authResponse.success) {
            // user authenticated, get the user record and access_token
            const user = authResponse.data.user;
            const access_token = authResponse.data.access_token;
            /* Check if the refresh token is still present and has
            not been deleted during the authentication process */
            const refresh_token = getRefreshTokenFromLocalStorage();
            if (refresh_token) {
              // set the user and access_token
              setUser(user);
              setAccessTokenInLocalStorage(access_token);
              // decode the jwt access_token
              const decoded_access_token = jwtDecode(access_token);
              setInventoryId(decoded_access_token.inventoryId);
            }
          } else {
            /*
              remove user, Inventory I'd and clear the local-storage except
              refresh_token.
            */
            setUser(null);
            setInventoryId('');
            clearLocalStorage();
            setRefreshTokenInLocalStorage(refresh_token);
          }
        })
        .finally(() =>  setLoading(false));
    });
  }, []);

  const removeReCaptchaContainer = () => {
    const previousReCaptchaContainer = document.getElementById(
      reCaptchaContainerId
    );
    if (previousReCaptchaContainer) {
      previousReCaptchaContainer.remove();
    }
  }

  const changeReCaptchaContainer = () => {
    // remove previous reCaptcha container
    removeReCaptchaContainer();
    // add new reCaptcha container
    const reCaptchaContainer = document.createElement('div');
    const id = `container-${Math.random().toString(36).substring(2, 10)}`;
    reCaptchaContainer.id = id;
    document.body.appendChild(reCaptchaContainer);
    setReCaptchaContainerId(id);
    return id;
  }

  const setUserAndTokens = (user, access_token, refresh_token) => {
    // clear the localstorage and set user, inventoryId, access & refresh token
    setUser(user);
    clearLocalStorage();
    setAccessTokenInLocalStorage(access_token);
    setRefreshTokenInLocalStorage(refresh_token);
    // decode the jwt access_token
    const decoded_access_token = jwtDecode(access_token);
    setInventoryId(decoded_access_token.inventoryId);
  }

  const handleCheckEmailIdExists = async (emailId) => {
    // notification
    const notificationId = notification.queueNotification(
      'Verifying email id...'
    );
    // check if email id already exists or not
    const checkEmailResponse = await requestCheckEmail(emailId);
    if (checkEmailResponse.status === 409) {
      // if a user exists (409 -> conflict i.e., user exists)
      // remove notification
      notification.removeNotification(notificationId);
      return { success: true };
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'User not found!', notificationId
    );
    return { success: false };
  };

  const handleCheckMobileNumberExists = async (mobileNumber) => {
    // notification
    const notificationId = notification.queueNotification(
      'Verifying mobile number...'
    );
    // check if mobile number already exists or not
    const checkMobileResponse = await requestCheckMobile(`+91${mobileNumber}`);
    if (checkMobileResponse.status === 409) {
      // if a user exists (409 -> conflict i.e., user exists)
      // remove notification
      notification.removeNotification(notificationId);
      return { success: true };
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'User not found!', notificationId
    );
    return { success: false };
  };

  const handleMobileNumberServerVerification = async (elevated_access_token) => {
    // notification
    const notificationId = notification.queueNotification('Signing in...');
    // send the Google token for server access token
    const serverAuthResponse = await requestChangeInventory(
      elevated_access_token, inventoryId
    );
    // check if user already exists or not
    if (serverAuthResponse.success && serverAuthResponse.status !== 404) {
      // notification (success)
      notification.setNotificationStatusSuccess(
        'Successfully logged in!', notificationId
      );
      // extract user, access_token, refresh_token from response
      const { user, access_token, refresh_token } = serverAuthResponse.data;
      // set user, access token and refresh token
      setUserAndTokens(user, access_token, refresh_token);
      // return the response
      return { success: true };
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'Sign-in failed!', notificationId
    );
    // return the response
    return { success: false };
  }

  const handleEmailIdServerVerification = async (elevated_access_token) => {
    // notification
    const notificationId = notification.queueNotification('Logging in...');
    // send the Google token for server access token
    const serverAuthResponse = await requestChangeInventory(
      elevated_access_token, inventoryId
    );
    // check if user already exists or not
    if (serverAuthResponse.success && serverAuthResponse.status !== 404) {
      // notification (success)
      notification.setNotificationStatusSuccess(
        'Successfully logged in!', notificationId
      );
      // extract user, access_token, refresh_token from response
      const { user, access_token, refresh_token } = serverAuthResponse.data;
      // set user, access token and refresh token
      setUserAndTokens(user, access_token, refresh_token);
      // return the response
      return { success: true };
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'User not found!', notificationId
    );
    // return the response
    return { success: false };
  }

  const logout = () => {
    // notification
    const notificationId = notification.queueNotification('Logging out...');
    /*
      remove user and clear the localstorage (access & refresh token will be
      cleared along with clear localstorage operation).
    */
    setUser(null);
    setInventoryId('');
    clearLocalStorage();
    // notification (success)
    notification.setNotificationStatusSuccess(
      'Successfully logged out!', notificationId
    );
    // return the response
    return { success: true };
  };

  return {
    user,
    inventoryId,
    setInventoryId,
    loading,
    changeReCaptchaContainer,
    removeReCaptchaContainer,
    setUserAndTokens,
    handleEmailIdServerVerification,
    handleCheckEmailIdExists,
    handleMobileNumberServerVerification,
    handleCheckMobileNumberExists,
    logout
  }
};