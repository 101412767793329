import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage, faImages, faCircleXmark, faArrowUpFromBracket
} from '@fortawesome/free-solid-svg-icons';
import '../../styles/Register/InputShopImages.scss';
import { useRegister } from '../../hooks';
import { FixedPopUp, ImageCropInput } from '../../components'

export default function InputShopImages() {
  const register = useRegister();
  const {
    shopCoverImage, setShopCoverImage,
    shopLogoImage, setShopLogoImage,
    shopCoverImageUrl, setShopCoverImageUrl,
    shopLogoImageUrl, setShopLogoImageUrl,
    moreImages, setMoreImages,
    shopThemeColors, setShopThemeColors,
    checkIsMoreImagesEmpty, checkIsMoreImagesFilled
  } = register;

  const [selectCoverPhoto, setSelectCoverPhoto] = useState(false);
  const [selectShopLogo, setSelectShopLogo] = useState(false)
  const moreImagesInputRef = useRef(null);

  const handleAddCoverPhotoOnClick = () =>
    setSelectCoverPhoto(!selectCoverPhoto);

  const handleAddShopLogoOnClick = () =>
    setSelectShopLogo(!selectShopLogo);

  const handleShopThemeColorChange = (event, key) =>
    setShopThemeColors({ ...shopThemeColors, [key]: event.target.value });

  const handleChooseImageFileButtonClick = () => {
    moreImagesInputRef.current.click();
  }

  const handleImagesFileSelect = (event) => {
    const selectedImages = Array.from(event.target.files);
    let newMoreImages = [...moreImages];
    let imageFillIndex = 0;
    newMoreImages.map(image => {
      if (!image.imageUrl && (imageFillIndex < selectedImages.length)) {
        image.image = selectedImages[imageFillIndex++];
        image.imageUrl = URL.createObjectURL(image.image);
      }
    });
    setMoreImages(newMoreImages);
  };

  return (
    <div className="input-shop-images">
      <div className="left-container">
        <div className="profile-image-inputs">
          <div className="cover-photo-container">
            {shopCoverImageUrl && (
              <img src={shopCoverImageUrl} alt="cover" />
            )}
          </div>

          <div className="shop-logo-container">
            {shopLogoImageUrl && (
              <img src={shopLogoImageUrl} alt="shop logo" />
            )}
          </div>

          <button
            className="select-image-btn select-cover-photo"
            onClick={handleAddCoverPhotoOnClick}
          >
            <FontAwesomeIcon icon={faImage} className="icon" />
            <p>{shopCoverImageUrl ? 'Edit Cover Image' : 'Select Cover Image'}</p>
          </button>

          <button
            className="select-image-btn select-shop-logo"
            onClick={handleAddShopLogoOnClick}
          >
            <FontAwesomeIcon icon={faImage} className="icon" />
            <p>{shopLogoImageUrl ? 'Edit Shop Logo' : 'Select Shop Logo'}</p>
          </button>
        </div>

        <div className="theme-color-inputs">
          <p className="head">Select your inventory theme colors:</p>

          <div className="inputs">
            <div className="color-input">
              <input
                type="color"
                value={shopThemeColors.primaryColor}
                onChange={(e) => handleShopThemeColorChange(e, 'primaryColor')}
              />
              <p>Primary Theme Color</p>
            </div>

            <div className="color-input">
              <input
                type="color"
                value={shopThemeColors.secondaryColor}
                onChange={(e) => handleShopThemeColorChange(e, 'secondaryColor')}
              />
              <p>Secondary Theme Color</p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="right-container"
        style={{ minHeight: (moreImages.length) ? null : 60 }}
      >
        <div className="more-images-container">
          {moreImages.map((image, index) => image.imageUrl ? (
            <div
              key={`image-container-${index}`}
              className="image-container"
            >
              <img src={image.imageUrl} alt={`Image ${index}`} />
              <button
                className="remove-image-btn"
                onClick={() => {}}
              >
                <FontAwesomeIcon icon={faCircleXmark} className="icon"/>
              </button>
            </div>
            ) : (
              <div key={`image-container-${index}`}></div>
            )
          )}
        </div>

        {checkIsMoreImagesEmpty() && (
          <button
            className="upload-more-images-btn-initial"
            onClick={handleChooseImageFileButtonClick}
          >
            <FontAwesomeIcon icon={faArrowUpFromBracket} className="icon"/>
            <p>Upload More Images</p>
          </button>
        )}

        {!checkIsMoreImagesEmpty() && !checkIsMoreImagesFilled() && (
          <button
            className="upload-more-images-btn"
            onClick={handleChooseImageFileButtonClick}
          >
            <FontAwesomeIcon icon={faImages} className="icon"/>
            <p>Add More Images</p>
          </button>
        )}

        <input
          style={{ display: 'none' }}
          ref={moreImagesInputRef}
          type="file"
          accept="image/*"
          onChange={handleImagesFileSelect}
          multiple
        />
      </div>

      {selectCoverPhoto && (
        <FixedPopUp>
          <div className="image-crop-input-container">
            <ImageCropInput
              aspectRatio={11/4}
              image={shopCoverImage}
              setImage={setShopCoverImage}
              imageUrl={shopCoverImageUrl}
              setImageUrl={setShopCoverImageUrl}
              removePopUpFunction={handleAddCoverPhotoOnClick}
              placeholder="Select Cover Image"
            />
          </div>
        </FixedPopUp>
      )}

      {selectShopLogo && (
        <FixedPopUp>
          <div className="image-crop-input-container">
            <ImageCropInput
              aspectRatio={1}
              image={shopLogoImage}
              setImage={setShopLogoImage}
              imageUrl={shopLogoImageUrl}
              setImageUrl={setShopLogoImageUrl}
              removePopUpFunction={handleAddShopLogoOnClick}
              placeholder="Select Shop Logo"
            />
          </div>
        </FixedPopUp>
      )}
    </div>
  );
}
