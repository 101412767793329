import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck, faArrowUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import '../styles/components/SelectPlans.scss';
import { useState } from 'react';

export default function SelectPlans(props) {
  const { onPlanSelect } = props;

  const navigate = useNavigate();
  const [selectedPlanDuration, setSelectedPlanDuration] = useState({
    freeTrial: 0, standard: 1, premium: 1
  });
  const [selectedPlan, setSelectedPlan] = useState({
    freeTrial: false, standard: false, premium: false
  })

  const WP_REDIRECT_URL = 'https://wa.me/8421888905'; // temp

  const features = {
    freeTrial: [
      "Inventory QR / Link",
      "Add 1 Combo Offer",
      "Add upto 50 Items",
      "Google Map Integration"
    ],
    standard: [
      "Inventory QR / Link",
      "Add Combo Offers",
      "Add Unlimited Items",
      "Google Map Integration",
      "AI Powered Promo Posters",
      "Customer Billing",
      "Basic Themes",
      "Manage Stock Quantity",
      "Manage Staff Permissions"
    ],
    premium: [
      "Inventory QR / Link",
      "Add Combo Offers",
      "Add Unlimited Items",
      "Google Map Integration",
      "AI Powered Promo Posters",
      "Customer Billing",
      "Premium Themes",
      "Manage Stock Quantity",
      "Manage Staff Permissions",
      "Manage Online Orders",
      "Customer Reviews",
      "AI Based Analytics",
      "Table Reservation"
    ]
  };

  const handleSelectPlanDurationButtonClick = (durationMonths, planType) => {
    setSelectedPlanDuration({
      ...selectedPlanDuration,
      [planType]: durationMonths
    });

    if (onPlanSelect) {
      setSelectedPlan({
        freeTrial: false, standard: false, premium: false, [planType]: true
      });
      onPlanSelect(planType, durationMonths);
    }
  }

  return (
    <div className="select-plans">
      {/* free-trial-plan */}
      <div
        className="plan-container free-trial-plan"
        style={{ borderColor: selectedPlan.freeTrial ? 'seagreen' : null }}
      >
        <div className="plan-heading"><p>Free Trial</p></div>
        <div className="price-and-label">
          <p className="price">₹ 0</p>
          <p className="label">30 days free trial</p>
        </div>
        <div className="title"><p>Get Started</p></div>
        <div className="register-now-container">
          {!onPlanSelect ? (
            <button onClick={() => navigate('/register')}>
              Try Ventory for free now
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon"/>
            </button>
          ) : (
            <button onClick={() => handleSelectPlanDurationButtonClick(0, 'freeTrial')}>
              Proceed with free plan
            </button>
          )}
        </div>
        <div className="blank-space"><div className="line"></div></div>
        <div className="feature-container">
          {features.freeTrial.map((feature, index) => (
            <div key={`feature-${index}`} className="feature">
              <FontAwesomeIcon icon={faCircleCheck} className="icon"/>
              <p>{feature}</p>
            </div>
          ))}
        </div>
      </div>

      {/* standard-plan */}
      <div
        className='plan-container standard-plan'
        style={{ borderColor: selectedPlan.standard ? 'seagreen' : null }}
      >
        <div className="best-value"><p>Best Value</p></div>
        <div className="plan-heading"><p>Standard</p></div>
        <div className="price-and-label">
          <p className="price">
            {(selectedPlanDuration.standard === 1) && "₹ 299 /-"}
            {(selectedPlanDuration.standard === 3) && "₹ 849 /-"}
            {(selectedPlanDuration.standard === 6) && "₹ 1599 /-"}
          </p>
          <p className="label">
            {(selectedPlanDuration.standard === 1) && "₹ 299 / Month"}
            {(selectedPlanDuration.standard === 3) && "₹ 283 / Month (Save ₹48)"}
            {(selectedPlanDuration.standard === 6) && "₹ 266 / Month (Save ₹198)"}
          </p>
        </div>
        <div className="title"><p>Start Scaling up</p></div>
        <div className="select-plan-duration">
          <button
            onClick={() => handleSelectPlanDurationButtonClick(1, 'standard')}
            style={{
              backgroundColor: (selectedPlanDuration.standard === 1)
                ? 'seagreen' : null,
              color: (selectedPlanDuration.standard === 1) ? 'white' : null
            }}
          >1 Month</button>
          <button
            onClick={() => handleSelectPlanDurationButtonClick(3, 'standard')}
            style={{
              backgroundColor: (selectedPlanDuration.standard === 3)
                ? 'seagreen' : null,
              color: (selectedPlanDuration.standard === 3) ? 'white' : null
            }}
          >3 Months</button>
          <button
            onClick={() => handleSelectPlanDurationButtonClick(6, 'standard')}
            style={{
              backgroundColor: (selectedPlanDuration.standard === 6)
                ? 'seagreen' : null,
              color: (selectedPlanDuration.standard === 6) ? 'white' : null
            }}
          >6 Months</button>
        </div>
        {/*temp*/}
        <div className="whatsapp-redirect">
          <a href={WP_REDIRECT_URL} target="_blank">
            <p>Proceed With Standard Plan</p>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon"/>
          </a>
        </div>
        <div className="blank-space"><div className="line"></div></div>
        <div className="feature-container">
          {features.standard.map((feature, index) => (
            <div key={`feature-${index}`} className="feature">
              <FontAwesomeIcon icon={faCircleCheck} className="icon"/>
              <p>{feature}</p>
            </div>
          ))}
        </div>
      </div>

      {/* premium-plan */}
      <div
        className='plan-container premium-plan'
        style={{ borderColor: selectedPlan.premium ? 'seagreen' : null }}
      >
        <div className="plan-heading"><p>Premium</p></div>
        <div className="price-and-label">
          <p className="price">
            {(selectedPlanDuration.premium === 1) && "₹ 499 /-"}
            {(selectedPlanDuration.premium === 3) && "₹ 1399 /-"}
            {(selectedPlanDuration.premium === 6) && "₹ 2599 /-"}
          </p>
          <p className="label">
            {(selectedPlanDuration.premium === 1) && "₹ 499 / Month"}
            {(selectedPlanDuration.premium === 3) && "₹ 466 / Month (Save ₹99)"}
            {(selectedPlanDuration.premium === 6) && "₹ 433 / Month (Save ₹396)"}
          </p>
        </div>
        <div className="title"><p>Keep Scaling up</p></div>
        <div className="select-plan-duration">
          <button
            onClick={() => handleSelectPlanDurationButtonClick(1, 'premium')}
            style={{
              backgroundColor: (selectedPlanDuration.premium === 1)
                ? 'seagreen' : null,
              color: (selectedPlanDuration.premium === 1) ? 'white' : null
            }}
          >1 Month</button>
          <button
            onClick={() => handleSelectPlanDurationButtonClick(3, 'premium')}
            style={{
              backgroundColor: (selectedPlanDuration.premium === 3)
                ? 'seagreen' : null,
              color: (selectedPlanDuration.premium === 3) ? 'white' : null
            }}
          >3 Months</button>
          <button
            onClick={() => handleSelectPlanDurationButtonClick(6, 'premium')}
            style={{
              backgroundColor: (selectedPlanDuration.premium === 6)
                ? 'seagreen' : null,
              color: (selectedPlanDuration.premium === 6) ? 'white' : null
            }}
          >6 Months</button>
        </div>
        {/*temp*/}
        <div className="whatsapp-redirect">
          <a href={WP_REDIRECT_URL} target="_blank">
            <p> Proceed With Premium Plan</p>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="icon"/>
          </a>
        </div>
        <div className="blank-space"><div className="line"></div></div>
        <div className="feature-container">
          {features.premium.map((feature, index) => (
            <div key={`feature-${index}`} className="feature">
              <FontAwesomeIcon icon={faCircleCheck} className="icon"/>
              <p>{feature}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}