import '../../../styles/Dashboard/Home/index.scss';
import TableSticker from './Canvas/TableSticker';
import InventoryCount from './InventoryCount';

export default function Home() {
  return (
    <div className="dashboard-home">
      <main>
        <TableSticker />
        {/*<InventoryCount />*/}
      </main>
    </div>
  );
}
