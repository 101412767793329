export function searchInInventory(inventory, searchString) {
  let localInventory = {order: []};

  // attempt to search items with matching category name
  let categoryOrder = inventory.order;
  for (const category_order_index in categoryOrder) {
    const category_key = categoryOrder[category_order_index];
    if (
      inventory[category_key]['categoryName'].toLowerCase()
        .includes(searchString.toLowerCase())
    ) {
      localInventory[category_key] = inventory[category_key];
      localInventory.order[localInventory.order.length] = category_key;
    }
  }

  // attempt to search items with matching category name
  for (const category_order_index in categoryOrder) {
    const category_key = categoryOrder[category_order_index];
    const itemOrder = inventory[category_key].items.order;
    for (const item_order_index in itemOrder) {
      const item_key = itemOrder[item_order_index];
      if (
        inventory[category_key].items[item_key].name.toLowerCase()
          .includes(searchString.toLowerCase())
      ) {
        if (!localInventory[category_key]) {
          localInventory[category_key] = {
            categoryName: inventory[category_key]['categoryName'],
            items: { order: [] }
          }
          localInventory.order[localInventory.order.length] = category_key;
        }
        if (!localInventory[category_key].items[item_key]){
          localInventory[category_key].items[item_key] = inventory[category_key].items[item_key];
          localInventory[category_key].items.order[localInventory[category_key].items.order.length] = item_key;
        }
      }
    }
  }

  return localInventory;
}