import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../../styles/Register/InputContainer.scss';

export default function InputContainer(props) {
  const {
    stepsToRegisterInfo, currentStepNumber,
    prevStepButtonOnClick, nextStepButtonOnClick,
    isLoading
  } = props;

  return (
    <div className="input-container">
      {/*top-section*/}
      <div className="top-section">
        <div className="step-title">
          <p style={{
            color: (currentStepNumber > 0) ? 'darkslategray' : 'dodgerblue'
          }}>{stepsToRegisterInfo[currentStepNumber].title}</p>
        </div>
      </div>

      {/*middle-section*/}
      <div className="middle-section"> {props.children} </div>

      {/*bottom-section*/}
      <div className="bottom-section">
        <div className="step-container">
          {(currentStepNumber > 1) ? (
            <button
              className="step-btn prev-step-btn"
              onClick={prevStepButtonOnClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="icon" />
            </button>
          ) : (<div className="null-container"></div>)}

          <div className="steps-indicators">
            {stepsToRegisterInfo.map((stepInfo, index) => (
              <div
                key={`step-indicator-${index}`}
                className="step-indicator"
                style={{
                  backgroundColor: (currentStepNumber === 0)
                    ? 'rgba(0, 0, 0, 0.1)' : (currentStepNumber === index)
                      ? 'goldenrod' : (stepInfo.progressionStatus === 'filled')
                        ? 'seagreen' : null
                }}
              ></div>
            ))}
          </div>

          {currentStepNumber && (currentStepNumber < 5) ? (
            <p
              className="next-step"
              onClick={(stepsToRegisterInfo[currentStepNumber]
                .progressionStatus === 'input_required')
                ? null : nextStepButtonOnClick
              }
              style={(stepsToRegisterInfo[currentStepNumber]
                .progressionStatus === 'input_required') ? {
                color: 'rgba(0, 0, 0, 0.3)',
                textDecoration: 'none',
                cursor: 'not-allowed'
              } : {}}
            >
              {(stepsToRegisterInfo[currentStepNumber]
                .progressionStatus === 'skip')
                ? 'Skip' : 'Next'
              }
            </p>
          ) : (<div className="null-container"></div>)}
        </div>

        <div className="loading">
          {isLoading && (<div className="bar"></div>)}
        </div>
      </div>
    </div>
  );
}