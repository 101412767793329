import { useContext, useEffect, useState } from 'react';
import { MD5 } from "crypto-js";
import { CanvasContext } from '../providers';
import { db, storage } from '../configs';
import { useDashboard } from '../hooks';
import {
  createTableSticker as requestCreateTableSticker
} from '../api';

export const useCanvas = () => {
  return useContext(CanvasContext);
};

export const useProvideCanvas = () => {
  const dashboard = useDashboard()
  const inventoryId = dashboard.inventoryId;
  const [loading, setLoading] = useState(true);
  const [featuredCanvas, setFeaturedCanvas] = useState({});
  const [myCanvas, setMyCanvas] = useState({});
  const [downloadQueue, setDownloadQueue] = useState({});

  useEffect(() => {
    if (inventoryId) {
      // get the featured canvas from the firebase
      db
        .collection('canvas')
        .doc('featured')
        .onSnapshot((doc) => {
          if (doc.exists) {
            setFeaturedCanvas(doc.data());
          }
        });

      // get your canvas from the firebase
      db
        .collection('canvas')
        .doc(inventoryId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setMyCanvas(doc.data());
          }
        });

      setLoading(false);
    }
  }, [inventoryId]);

  const scheduleCanvasDownloadTask = (canvasId) => {
    setDownloadQueue({
      ...downloadQueue,
      [canvasId]: canvasId
    });
  };

  const clearCanvasDownloadTask = (canvasId) => {
    if (downloadQueue[canvasId]){
      let tempDownloadQueue = {...downloadQueue};
      delete tempDownloadQueue[canvasId];
      setDownloadQueue(tempDownloadQueue);

      return canvasId;
    }
    return null;
  };

  const downloadCanvasImage = (imageUrl) => {
    storage.ref().child(imageUrl).getDownloadURL().then((imageUrl) => {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `${inventoryId}.png`;
          a.click();
        });
    });
  };

  const createTableSticker = async (stickerData) => {
    let canvasId = '';
    Object.keys(stickerData).map((stickerElement) => {
      canvasId = `${canvasId}${stickerData[stickerElement]}`;
    });
    canvasId = MD5(canvasId).toString();

    // check if required sticker already exists or not
    let isCanvasExists = false;
    if (myCanvas['table-sticker']) {
      myCanvas['table-sticker'].map((tableStickerData) => {
        if (canvasId === tableStickerData['canvasId']){
          isCanvasExists = true;
        }
      });
    }
    if (isCanvasExists) {
      return canvasId;
    }

    // API: create a new table sticker request
    await requestCreateTableSticker(inventoryId, stickerData);
    scheduleCanvasDownloadTask(canvasId);
    return null;
  };

  return {
    loading,
    featuredCanvas,
    myCanvas,
    scheduleCanvasDownloadTask,
    clearCanvasDownloadTask,
    downloadCanvasImage,
    createTableSticker
  };
};
