import '../../../styles/Dashboard/Analytics/index.scss';
import analytics_coming_soon from '../../../assets/dashboard/analytics-cs.svg'

export default function Analytics() {
  return (
    <div className="analytics">
      <main>
        <img src={analytics_coming_soon} alt=''/>
      </main>
    </div>
  );
}