import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy, faCheck, faDownload, faArrowRotateRight
} from '@fortawesome/free-solid-svg-icons';
import '../../../../../styles/Dashboard/Home/Canvas/TableSticker/index.scss'
import { useDashboard, useCanvas } from '../../../../../hooks';
import Sticker from './Sticker';
import { Loader } from '../../../../../components'

export default function TableSticker() {
  const dashboard = useDashboard();
  const canvas = useCanvas();
  const inventoryList = dashboard.inventoryList;
  const inventoryId = dashboard.inventoryId;
  const inventoryUrl = `https://ventory.co.in/inventory/${inventoryId}`;
  const [copied, setCopied] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [defaultShopName, setDefaultShopName] = useState('');
  const [stickerData, setStickerData] = useState({
    bgColor1: '#6FCCFF',
    bgColor2: '#2E8B57',
    qrColor: '#516395',
    snColor: '#FFFFFF',
    snBgColor: '#516395',
    shopName: ''
  });

  useEffect(() => {
    // get the shop name
    inventoryList.map((inventory) => {
      if (inventory.inventoryId === inventoryId) {
        setDefaultShopName(inventory.shopName);
        setStickerData({
          ...stickerData,
          shopName: inventory.shopName
        });
      }
    });
  }, []);

  useEffect(() => {
    if (canvas.myCanvas['table-sticker']) {
      canvas.myCanvas['table-sticker'].map((stickerData) => {
        const canvasId = canvas.clearCanvasDownloadTask(stickerData['canvasId']);
        if (canvasId){
          handleTableStickerDownload(canvasId).then();
        }
      });
    }
  }, [canvas.myCanvas['table-sticker']]);

  const handleCopyUrlToClipboard = () => {
    navigator.clipboard.writeText(inventoryUrl).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  const handleStickerDataChange = (event, key) => {
    setStickerData({
      ...stickerData,
      [key]: event.target.value
    });
  };

  const resetStickerDataToDefault = () => {
    setStickerData({
      bgColor1: '#6FCCFF',
      bgColor2: '#2E8B57',
      qrColor: '#516395',
      snColor: '#FFFFFF',
      snBgColor: '#516395',
      shopName: defaultShopName
    });
  };

  const handleTableStickerDownload = async () => {
    setDownloading(true);
    const canvasId = await canvas.createTableSticker(stickerData);

    if (canvasId) {
      const imageUrl = `${inventoryId}/canvas/${canvasId}`;
      canvas.downloadCanvasImage(imageUrl);
      setDownloading(false);
    }
  }

  return (
    <div className="table-sticker">
      <div className="sticker-container">
        <Sticker
          inventoryUrl={inventoryUrl}
          stickerData={stickerData}
        />

        <div className="sticker-edit">
          <div className="sticker-color-edit">
            <div className="color-input">
              <input
                type="color"
                value={stickerData.bgColor1}
                onChange={(event) => handleStickerDataChange(event, 'bgColor1')}
              />
              <p>BG Gradient Color 1</p>
            </div>
            <div className="color-input">
              <input
                type="color"
                value={stickerData.bgColor2}
                onChange={(event) => handleStickerDataChange(event, 'bgColor2')}
              />
              <p>BG Gradient Color 2</p>
            </div>
            <div className="color-input">
              <input
                type="color"
                value={stickerData.qrColor}
                onChange={(event) => handleStickerDataChange(event, 'qrColor')}
              />
              <p>QR Code Color</p>
            </div>
            <div className="color-input">
              <input
                type="color"
                value={stickerData.snColor}
                onChange={(event) => handleStickerDataChange(event, 'snColor')}
              />
              <p>Shop Name Color</p>
            </div>
            <div className="color-input">
              <input
                type="color"
                value={stickerData.snBgColor}
                onChange={(event) => handleStickerDataChange(event, 'snBgColor')}
              />
              <p>Shop Name BG Color</p>
            </div>
          </div>

          <div className="sticker-edit-btn">
            <button
              className="download-icon"
              onClick={handleTableStickerDownload}
            >
              {downloading ? (
                <Loader
                  color={'#516395'}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faDownload}
                  className="icon"
                />
              )}
            </button>

            <button
              className="reset-icon"
              onClick={resetStickerDataToDefault}
            >
              <p>Reset to Defaults</p>
              <FontAwesomeIcon
                icon={faArrowRotateRight}
                className="icon"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="inventory-url">
        <p>{inventoryUrl}</p>

        <div
          className="copy-icon"
          onClick={handleCopyUrlToClipboard}
          style={{cursor: copied ? "unset" : "pointer"}}
        >
          {copied ? (
            <FontAwesomeIcon
              className="icon"
              icon={faCheck}
              style={{color: "seagreen"}}
            />
          ) : (
            <FontAwesomeIcon
              className="icon"
              icon={faCopy}
              style={{color: "#516395"}}
            />
          )}
        </div>
      </div>
    </div>
  );
}