import '../styles/components/VentoryLoader.scss';

export default function VentoryLoader(props) {
  const { height } = props

  return (
    <div className="ventory-loader" style={{ height }}>
      {/*lines*/}
      <div className="vl-line vl-line-1"></div>
      <div className="vl-line vl-line-2"></div> {/*shop-left-round-corner*/}
      <div className="vl-line vl-line-3"></div> {/*shop-right-round-corner*/}
      <div className="vl-line vl-line-4"></div>
      <div className="vl-line vl-circle vl-line-5"></div>
      <div className="vl-line vl-circle vl-line-6"></div>
      <div className="vl-line vl-circle vl-line-7"></div>
      <div className="vl-line vl-line-8"></div> {/*gate-left-round-corner*/}
      <div className="vl-line vl-line-9"></div> {/*gate-right-round-corner*/}
      <div className="vl-line vl-line-10"></div> {/*gate-handle*/}

      {/*boxes*/}
      <div className="vl-box vl-box-1">
        <div className="vl-box-inner-1"></div>
      </div>

      {/*semi circular boxes*/}
      <div className="vl-box vl-semi-circle vl-box-2">
        <div className="vl-box-inner-2"></div>
      </div>
      <div className="vl-box vl-semi-circle vl-box-3">
        <div className="vl-box-inner-3"></div>
      </div>
      <div className="vl-box vl-semi-circle vl-box-4">
        <div className="vl-box-inner-4"></div>
      </div>
      <div className="vl-box vl-semi-circle vl-box-5">
        <div className="vl-box-inner-5"></div>
      </div>
      {/*-------------------*/}

      <div className="vl-box vl-box-6">
        <div className="vl-box-inner-6"></div>
      </div>

      <div className="vl-box vl-box-7">
        <div className="vl-box-inner-7"></div>
      </div>

      {/*Gate*/}
      <div className="vl-box vl-box-8">
        <div className="vl-box-inner-8"></div>
      </div>

      {/*Window*/}
      <div className="vl-box vl-box-9">
        <div className="vl-box-inner-9"></div>
      </div>
    </div>
  );
}
