import { useContext, useEffect, useState, useRef } from 'react';
import { NotificationContext } from '../providers';
import { sleep, CountUtils } from '../utils';

export const useNotification = () => {
  return useContext(NotificationContext);
};

export const useProvideNotification = () => {
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [queueIndexManager, setQueueIndexManager] = useState(null);

  useEffect(() => {
    setQueueIndexManager(new CountUtils());
  }, []);

  useEffect(() => {
    // Check if all values in the notifications array are undefined
    if (notificationQueue.length > 0 &&
      notificationQueue.every((notification) => notification === undefined)
    ) {
      // Empty the list
      setNotificationQueue([]);
      queueIndexManager.setCountToZero();
    }
  }, [notificationQueue]);

  const queueNotification = (message='') => {
    // add the new notification to the notification queue
    const newNotification = {
      type: 'loading',
      message: message
    };

    setNotificationQueue(
      (prevNotificationQueue) => [...prevNotificationQueue, newNotification]
    );

    // get the notification index
    queueIndexManager.incrementCount();

    return queueIndexManager.getCount() - 1;
  }

  const removeNotification = (notificationIndex) => {
    // remove the notification from the notification queue
    setNotificationQueue((prevNotificationQueue) => {
      const updatedNotifications = [...prevNotificationQueue];
      updatedNotifications[notificationIndex] = undefined;
      return updatedNotifications;
    });
  }

  const setNotificationStatusSuccess = async (message, notificationIndex) => {
    const newNotification = {
      type: 'success',
      message: message
    };
    setNotificationQueue((prevNotificationQueue) =>
      prevNotificationQueue.map((notification, i) =>
        i === notificationIndex ? newNotification : notification
      )
    );
    // set the notification to remove after 4 seconds
    await sleep(4000);
    removeNotification(notificationIndex);
  }

  const setNotificationStatusFailed = async (message, notificationIndex) => {
    const newNotification = {
      type: 'failed',
      message: message
    };
    setNotificationQueue((prevNotificationQueue) =>
      prevNotificationQueue.map((notification, i) =>
        i === notificationIndex ? newNotification : notification
      )
    );
    // set the notification to remove after 4 seconds
    await sleep(4000);
    removeNotification(notificationIndex);
  }

  return {
    notificationQueue,
    queueNotification,
    removeNotification,
    setNotificationStatusSuccess,
    setNotificationStatusFailed
  };
};
