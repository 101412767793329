import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import '../../../styles/Dashboard/SideBar/index.scss';
import SelectDashboardOption from './SelectDashboardOption';
import full_logo from '../../../assets/shared/full-logo.png'
import logo from '../../../assets/shared/logo.png'

export default function SideBar(props) {
  const { handleDashboardOptionClick, selectedDashboardOption } = props;
  const isSmallScreen = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const [logoImage, setLogoImage] = useState(full_logo);
  const navigate = useNavigate();

  useEffect(() => {
    setLogoImage(isSmallScreen ? logo : full_logo);
  }, [isSmallScreen]);

  return (
    <div className="side-bar">
      <div
        className="logo-container"
        onClick={() => navigate('/')}
      >
        <img src={logoImage} alt="ventory-logo"/>
      </div>

      <div className="side-bar-main">
        <div className="select-dashboard-option-container">
          <SelectDashboardOption
            handleDashboardOptionClick={handleDashboardOptionClick}
            selectedDashboardOption={selectedDashboardOption}
          />
        </div>
      </div>
    </div>
  );
}
