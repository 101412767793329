import jwtDecode from 'jwt-decode';
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup
} from 'firebase/auth';
import { auth } from '../configs';
import { emailAuthSendOtp, emailAuthVerifyOtp } from '../api';
import {
  setItemInLocalStorageCrypt,
  getItemFromLocalStorageCrypt,
} from './LocalStorageUtils';

export const isTokenDateValid = (token) => {
  // check for token expiration data
  token = jwtDecode(token);
  // return true if not expired else false
  return (token.exp && Date.now() / 1000 < token.exp);
};

export const sendOtpToEmailId = async (emailId) => {
  // return the response
  return await emailAuthSendOtp(emailId);
};

export const verifyEmailIdOtp = async (otp, session_token) => {
  // request otp confirmation
  const otpVerificationResponse = await emailAuthVerifyOtp(otp, session_token);
  if (otpVerificationResponse.success) {
    const elevated_access_token = otpVerificationResponse.data['elevated_access_token']

    // set the elevated access token to local storage
    setElevatedAccessTokenInLocalStorage(elevated_access_token)

    // return Google access token (success)
    return {
      success: true,
      elevated_access_token
    };
  }
  // return the response (failure)
  return { success: false };
};

export const sendOtpToMobileNumber = async (mobileNumber, reCaptchaContainerId) => {
  try {
    // create a recaptcha verifier
    window.recaptchaVerifier = new RecaptchaVerifier(
      reCaptchaContainerId,
      { size: "invisible" },
      auth
    );
    // display recaptcha verifier
    const appVerifier = window.recaptchaVerifier;
    // define mobile format
    const mobileFormat = `+91${mobileNumber}`;
    // on captcha confirmation, sign-in using mobile number
    window.confirmationResult = await signInWithPhoneNumber(
      auth, mobileFormat, appVerifier
    );
    // return the response (success)
    return { success: true };
  } catch (error) {}
  // return the response (failure)
  return { success: false };
};

export const verifyMobileNumberOtp = async (otp) => {
  try {
    // request otp confirmation
    const result = await window.confirmationResult.confirm(otp);
    const elevated_access_token = result.user['accessToken']

    // set the elevated access token to local storage
    setElevatedAccessTokenInLocalStorage(elevated_access_token)

    // return Google access token (success)
    return {
      success: true,
      elevated_access_token
    };
  } catch (error) {}

  // return the response (failure)
  return { success: false };
};

export const authenticateWithGoogle = async () => {
  try {
    // sign in with a pop-up window
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const elevated_access_token = result.user['accessToken']

    // set the elevated access token to local storage
    setElevatedAccessTokenInLocalStorage(elevated_access_token)

    // return Google access token (success)
    return {
      success: true,
      elevated_access_token
    };
  } catch (error) {}

  // return the response (failure)
  return { success: false };
};

// localstorage get, set methods for tokens
export const setAccessTokenInLocalStorage = (access_token) =>
  setItemInLocalStorageCrypt('__access_token__', access_token);
export const getAccessTokenFromLocalStorage = async () => {
  return await getItemFromLocalStorageCrypt('__access_token__');
}

export const setRefreshTokenInLocalStorage = (refresh_token) =>
  setItemInLocalStorageCrypt('__refresh_token__', refresh_token);
export const getRefreshTokenFromLocalStorage = async () => {
  return await getItemFromLocalStorageCrypt('__refresh_token__');
}

export const setElevatedAccessTokenInLocalStorage = (elevated_access_token) =>
  setItemInLocalStorageCrypt('__elevated_access_token__',elevated_access_token);
export const getElevatedAccessTokenFromLocalStorage = async () => {
  return await getItemFromLocalStorageCrypt('__elevated_access_token__');
}