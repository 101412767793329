import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RegisterContext } from '../providers';
import { useNotification } from './useProvideNotification';
import { useAuth } from './useProvideAuth';
import {
  createUser as requestCreateUser,
  checkMobile as requestCheckMobile,
  checkEmail as requestCheckEmail,
} from '../api';
import {
  setItemInLocalStorageCrypt,
  getItemFromLocalStorageCrypt,
  isValidHexColor
} from '../utils';
import { getDefaultCategories } from '../firebase/defaults'

export const useRegister = () => {
  return useContext(RegisterContext);
}

export const useProvideRegister = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const notification = useNotification();
  const auth = useAuth();  // custom auth hook

  // define the steps required to register a new user
  const [stepsToRegisterInfo, setStepsToRegisterInfo] = useState([
    {
      title: "🌟 VERIFY YOUR CREDENTIALS 🌟",
      progressionStatus: 'input_required'
    }, {
      title: "🛍️ TELL US ABOUT YOUR SHOP 🛍️",
      progressionStatus: 'input_required'
    }, {
      title: "📸 BLING OUT YOUR SHOP WITH PHOTOS! 📸",
      progressionStatus: 'skip'
    }, {
      title: "📞 HOW CAN A CUSTOMER CONTACT YOU 📞",
      progressionStatus: 'skip'
    }, {
      title: "📍 TELL US ABOUT YOUR SHOP LOCATION AND TIMINGS 🕰️🌍",
      progressionStatus: 'skip'
    }, {
      title: "🏪 TELL US MORE ABOUT YOUR SHOP 🌟",
      progressionStatus: 'skip'
    }
  ]);
  // define current step information
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  // define loader
  const [isLoading, setIsLoading] = useState(false);

  // states for 'is local-storage operation done' for all 6 individual steps
  const [isLocalStorageStep1Done, setIsLocalStorageStep1Done] = useState(false);
  const [isLocalStorageStep2Done, setIsLocalStorageStep2Done] = useState(false);
  const [isLocalStorageStep3Done, setIsLocalStorageStep3Done] = useState(false);
  const [isLocalStorageStep4Done, setIsLocalStorageStep4Done] = useState(false);
  const [isLocalStorageStep5Done, setIsLocalStorageStep5Done] = useState(false);
  const [isLocalStorageStep6Done, setIsLocalStorageStep6Done] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stepNumber = parseInt(queryParams.get('step'));
    if (auth.user) {
      let newStepsToRegisterInfo = [...stepsToRegisterInfo];
      newStepsToRegisterInfo[0].progressionStatus = 'filled';
      setStepsToRegisterInfo(newStepsToRegisterInfo);

      if (stepNumber && (stepNumber > 0) && (stepNumber < 6)) {
        setCurrentStepNumber(stepNumber);
        navigate(`/register?step=${stepNumber}`, { replace: true });
      } else {
        setCurrentStepNumber(1);
        navigate('/register?step=1', { replace: true });
      }
    } else {
      setCurrentStepNumber(0);
      navigate('/register', { replace: true });
    }
  }, [location.search, auth.user]);

  const goBackToPrevStep = () => {
    if (currentStepNumber > 1) {
      navigate(`/register?step=${currentStepNumber - 1}`, { replace: true });
    }
  }

  const moveToNextStep = () => {
    if (currentStepNumber < 5) {
      navigate(`/register?step=${currentStepNumber + 1}`, { replace: true });
    }
  }

  const setStepProgressStatus = (stepIndex, status) => {
    let newStepsToRegisterInfo = [...stepsToRegisterInfo];
    newStepsToRegisterInfo[stepIndex].progressionStatus = status;
    setStepsToRegisterInfo(newStepsToRegisterInfo);
  }

  // -----STEP '0' VERIFY CREDENTIALS-------------------------------------------
  const { user, inventoryId } = auth;
  const [isUserExists, setIsUserExists] = useState(false);

  useEffect(() => {
    // check if all the required details are present
  }, [user]);

  const handleEmailIdServerVerification = async (auth_token) => {
    // notification
    const notificationId = notification.queueNotification('Signing up...');
    setIsLoading(true);
    // send the Google token for server access token
    const serverAuthResponse = await requestCreateUser(auth_token, 'email');
    // check if user already exists or not
    if (serverAuthResponse.success && serverAuthResponse.status !== 409) {
      // notification (success)
      notification.setNotificationStatusSuccess(
        'Successfully signed up!', notificationId
      );
      setIsLoading(false);
      // extract user, access_token, refresh_token from response
      const { user, access_token, refresh_token } = serverAuthResponse.data;
      // set user, access token and refresh token
      auth.setUserAndTokens(user, access_token, refresh_token);
      // return the response
      return { success: true };
    } else if (serverAuthResponse.status === 409) {
      // if user exists (409 -> user found)
      setIsUserExists(true);
    }
    setIsLoading(false);
    // notification (failed)
    notification.setNotificationStatusFailed(
      'User already exists!', notificationId
    );
    // return the response
    return { success: false };
  };

  const handleCheckEmailIdExists = async (emailId) => {
    // notification
    const notificationId = notification.queueNotification(
      'Verifying Email Id...'
    );
    // check if email id already exists or not
    const checkEmailResponse = await requestCheckEmail(emailId);
    if (checkEmailResponse.status === 404) {
      // if a user does not exist (404 -> user not found)
      // remove notification
      notification.removeNotification(notificationId);
      return { success: true };
    } else if (checkEmailResponse.status === 409) {
      // if user exists (409 -> user found)
      setIsUserExists(true);
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'User already exists!', notificationId
    );
    return { success: false };
  }

  const handleCheckMobileNumberExists = async (mobileNumber) => {
    // notification
    const notificationId = notification.queueNotification(
      'Verifying mobile number...'
    );
    // check if mobile number already exists or not
    const checkMobileResponse = await requestCheckMobile(`+91${mobileNumber}`);
    if (checkMobileResponse.status === 404) {
      // if a user does not exist (404 -> user not found)
      // remove notification
      notification.removeNotification(notificationId);
      return { success: true };
    } else if (checkMobileResponse.status === 409) {
      // if user exists (409 -> user found)
      setIsUserExists(true);
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'User already exists!', notificationId
    );
    return { success: false };
  }

  const handleMobileNumberServerVerification = async (google_token) => {
    // notification
    const notificationId = notification.queueNotification('Signing up...');
    setIsLoading(true);
    // send the Google token for server access token
    const serverAuthResponse = await requestCreateUser(google_token, 'mobile');
    // check if user already exists or not
    if (serverAuthResponse.success && serverAuthResponse.status !== 404) {
      // notification (success)
      notification.setNotificationStatusSuccess(
        'Successfully signed up!', notificationId
      );
      setIsLoading(false);
      // extract user, access_token, refresh_token from response
      const { user, access_token, refresh_token } = serverAuthResponse.data;
      // set user, access token and refresh token
      auth.setUserAndTokens(user, access_token, refresh_token);
      // return the response
      return { success: true };
    }
    // notification (failed)
    notification.setNotificationStatusFailed(
      'Signup failed!', notificationId
    );
    // return the response
    return { success: false };
  }

  const removeIsUserExistsPrompt = () => setIsUserExists(false);

  const handleLogout = () => auth.logout();

  // -----STEP '1' TELL US ABOUT YOUR SHOP--------------------------------------
  const [shopOwnerName, setShopOwnerName] = useState('');
  const [shopName, setShopName] = useState('');
  const [aboutShop, setAboutShop] = useState('');
  const [shopCategory, setShopCategory] = useState('');
  const [shopSubCategory, setShopSubCategory] = useState('');
  const [availableCategoriesList, setAvailableCategoriesList] = useState({});
  const [
    shopSubCategoryInputOption, setShopSubCategoryInputOption
  ] = useState("dropdown"); // dropdown or text

  useEffect(() => {
    // recover input values from local storage
    const recoverValuesFromLocalStorage = async () => {
      const shopName =
        await getItemFromLocalStorageCrypt('register/shop-name');
      if (shopName) {setShopName(shopName);}

      const shopOwnerName =
        await getItemFromLocalStorageCrypt('register/shop-owner-name');
      if (shopOwnerName) {setShopOwnerName(shopOwnerName);}

      const shopCategory =
        await getItemFromLocalStorageCrypt('register/shop-category');
      if (shopCategory) {setShopCategory(shopCategory);}

      const shopSubCategory =
        await getItemFromLocalStorageCrypt('register/shop-sub-category');
      if (shopSubCategory) {setShopSubCategory(shopSubCategory);}

      const aboutShop =
        await getItemFromLocalStorageCrypt('register/about-shop');
      if (aboutShop) { setAboutShop(aboutShop);}
    }
    recoverValuesFromLocalStorage().then(()=>setIsLocalStorageStep1Done(true));

    // fetch a default categories list from firebase
    const dbGetDefaultCategories = async () => {
      setAvailableCategoriesList(await getDefaultCategories());
    }
    dbGetDefaultCategories().then();
  }, []);

  useEffect(() => {
    // set input values in local storage
    if (isLocalStorageStep1Done) {
      setItemInLocalStorageCrypt('register/shop-name', shopName);
      setItemInLocalStorageCrypt('register/shop-owner-name', shopOwnerName);
      setItemInLocalStorageCrypt('register/shop-category', shopCategory);
      setItemInLocalStorageCrypt('register/shop-sub-category', shopSubCategory);
      setItemInLocalStorageCrypt('register/about-shop', aboutShop);
    }
    /*
     check if minimum inputs required to proceed are filled (minimum inputs
     required for step 1 are shop name, shop owner name, shop category &
     shop subcategory)
    */
    if (shopName && shopOwnerName && shopCategory && shopSubCategory) {
      setStepProgressStatus(1, 'filled');
    } else {
      setStepProgressStatus(1, 'input_required');
    }
  },[shopName, shopOwnerName, shopCategory, shopSubCategory, aboutShop]);

  const handleShopOwnerNameChange = (e) => setShopOwnerName(e.target.value);
  const handleShopNameChange = (e) => setShopName(e.target.value);
  const handleAboutShopChange = (e) => setAboutShop(e.target.value);
  const handleSelectShopCategoryChange = (category) =>
    setShopCategory(category);
  const handleSelectShopSubCategoryDropdownChange = (subCategory) => {
    if (subCategory === "other") {setShopSubCategoryInputOption("text");}
    setShopSubCategory(subCategory);
  }
  const handleSelectShopSubCategoryTextInputChange = (e) =>
    setShopSubCategory(e.target.value);
  const resetShopCategoryInfo = () => {
    setShopCategory('');
    setShopSubCategory('');
    setShopSubCategoryInputOption("dropdown");
  }

  // -----STEP '2' BLING OUT YOUR SHOP WITH PHOTOS------------------------------
  const [shopCoverImage, setShopCoverImage] = useState(null);
  const [shopCoverImageUrl, setShopCoverImageUrl] = useState('');
  const [shopLogoImage, setShopLogoImage] = useState(null);
  const [shopLogoImageUrl, setShopLogoImageUrl] = useState('');
  const [moreImages, setMoreImages] = useState([
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''},
    {image: null, imageUrl: ''}
  ]); // at max, 10 more images are allowed.
  const [shopThemeColors, setShopThemeColors] = useState({
    primaryColor: "#516395",
    secondaryColor: "#2E8B57"
  });

  useEffect(() => {
    // recover color input values from local storage
    const recoverValuesFromLocalStorage = async () => {
      const colors =
        await getItemFromLocalStorageCrypt('register/shop-theme-colors');

      if (colors) {
        try {
          const parsedColors = JSON.parse(colors);
          if (isValidHexColor(parsedColors.primaryColor) &&
            isValidHexColor(parsedColors.secondaryColor)
          ) {
            setShopThemeColors(parsedColors);
          }
        } catch (error) {}
      }
    }
    recoverValuesFromLocalStorage().then(()=>setIsLocalStorageStep2Done(true));
  }, []);

  useEffect(() => {
    // set input values in local storage
    if (isLocalStorageStep2Done) {
      setItemInLocalStorageCrypt('register/shop-theme-colors', shopThemeColors);
    }
    /*
     check if minimum inputs required to proceed are filled (minimum inputs
     required for step 2 are shopThemeColors (primaryColor & secondaryColor))
    */
    if (
      shopThemeColors.primaryColor !== "#516395" ||
      shopThemeColors.secondaryColor !== "#2E8B57"
    ) {setStepProgressStatus(2, 'filled');}
    else {setStepProgressStatus(2, 'skip');}
  },[shopThemeColors]);

  const checkIsMoreImagesEmpty = () => {
    return moreImages.every(image => !image.imageUrl);
  }

  const checkIsMoreImagesFilled = () => {
    let isMoreImagesFilled = true;
    moreImages.map(image => {
      if (!image.imageUrl) {isMoreImagesFilled = false;}
    });
    return isMoreImagesFilled;
  }

  return {
    isLoading,
    stepsToRegisterInfo, currentStepNumber,
    goBackToPrevStep, moveToNextStep,
    isUserExists, // STEP-0 (AUTHENTICATION)
    removeIsUserExistsPrompt,
    handleCheckEmailIdExists,
    handleCheckMobileNumberExists,
    handleMobileNumberServerVerification,
    handleEmailIdServerVerification,
    handleLogout,
    shopOwnerName, handleShopOwnerNameChange, // STEP-1
    shopName, handleShopNameChange,
    availableCategoriesList, shopSubCategoryInputOption,
    shopCategory, handleSelectShopCategoryChange,
    shopSubCategory, handleSelectShopSubCategoryDropdownChange,
    handleSelectShopSubCategoryTextInputChange, resetShopCategoryInfo,
    aboutShop, handleAboutShopChange,
    shopCoverImage, setShopCoverImage, // STEP-2
    shopLogoImage, setShopLogoImage,
    shopCoverImageUrl, setShopCoverImageUrl,
    shopLogoImageUrl, setShopLogoImageUrl,
    moreImages, setMoreImages,
    shopThemeColors, setShopThemeColors,
    checkIsMoreImagesEmpty, checkIsMoreImagesFilled
  };
};