import { HOST_IP } from '../configs/backend';
import { request } from './request';

const ENDPOINTS = {
  createUser: `${HOST_IP}/user/create-user`,
  createInventory: `${HOST_IP}/user/create-inventory`
};

export const createUser = (auth_token, credentialType) => {
  return request(
    ENDPOINTS.createUser,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        auth_token,
        credentialType
      })
    },
    false
  );
}