export function isNumber(value) {
  return typeof value === 'number' && !Number.isNaN(value);
}

export const validateNumber = (number) => {
  return number.replace(/[^0-9]/g, '');
}

export const validateMobileNumber = (mobileNumber) => {
  return mobileNumber.replace(/[^0-9]/g, '').length === 10;
}