import { request } from './request';

const ENDPOINTS = {
  termsAndConditions: 'https://raw.githubusercontent.com/GrigTechnologies/legal-agreements/main/terms-and-conditions.md',
  privacyAndPolicy: 'https://raw.githubusercontent.com/GrigTechnologies/legal-agreements/main/privacy-and-policy.md',
  returnPolicy: 'https://raw.githubusercontent.com/GrigTechnologies/legal-agreements/main/return-and-refund-policy.md',
};

export const getTermsAndConditions = () => {
  return request(
    ENDPOINTS.termsAndConditions,
    { method: 'GET' },
    false, // enableJWT
    true // returnResponseText
  );
};

export const getPrivacyAndPolicy = () => {
  return request(
    ENDPOINTS.privacyAndPolicy,
    { method: 'GET' },
    false, // enableJWT
    true // returnResponseText
  );
};

export const getReturnAndRefundPolicy = () => {
  return request(
    ENDPOINTS.returnPolicy,
    { method: 'GET' },
    false, // enableJWT
    true // returnResponseText
  );
};