import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/Dashboard/index.scss';
import { Loader } from "../../components";
import { useDashboard } from '../../hooks';
import {
  CanvasProvider,
  ManageInventoryProvider
} from '../../providers';
import SideBar from './SideBar';
import Home from './Home';
import ManageInventory from './ManageInventory';
import ManageOffers from './ManageOffers';
import CustomerBilling from './CustomerBilling';
import ManageOrders from './ManageOrders';
import Analytics from './Analytics';
import ManageProfile from './ManageProfile';

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const dashboard = useDashboard();
  const inventoryList = dashboard.inventoryList;

  const [selectedTaskWindow, setSelectedTaskWindow] = useState('');

  useEffect(() => {
    if (inventoryList.length) {
      dashboard.changeInventory(inventoryList[0]);
    } else {
      navigate('/register');
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSelectedTaskWindow(queryParams.get('v'));
  }, [location.search]);

  if (dashboard.isLoading){
    return (<Loader />);
  }

  return (
    <div className="dashboard">
      <div className="left-window">
        <SideBar />
      </div>

      <div className="right-window">
        {selectedTaskWindow === 'manage-inventory' ? (
            <ManageInventoryProvider>
              <ManageInventory />
            </ManageInventoryProvider>
          ) : selectedTaskWindow === 'manage-offers' ? (
            <ManageOffers />
          ) : selectedTaskWindow === 'customer-billing' ? (
            <CustomerBilling />
          ) : selectedTaskWindow === 'manage-orders' ? (
            <ManageOrders />
          ) : selectedTaskWindow === 'analytics' ? (
            <Analytics />
          ) : selectedTaskWindow === 'manage-profile' ? (
            <ManageProfile />
          ) : (
            <CanvasProvider><Home /></CanvasProvider>
        )}
      </div>
    </div>
  );
}
