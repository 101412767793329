import '../../../../styles/Dashboard/ManageInventory/PreviewInventory/DeleteItem.scss';
import ActionButton from '../../../../components/ActionButton';
import { useDashboard } from '../../../../hooks';

export default function DeleteItem(props) {
  const { itemToDelete, removePopUpFunction } = props;
  const dashboard = useDashboard();

  const requestDeleteItem = async () => {
    removePopUpFunction();

    // request delete item
    await dashboard.deleteItem(
      itemToDelete.category,
      itemToDelete.itemId
    );
  }

  return (
    <div className="delete-item">
      <div className="text">
        <p>Confirm Delete Item : <span>{itemToDelete.name}</span></p>
      </div>

      <div className="safe-exit">
        <ActionButton
          cancelButtonName={'Cancel'}
          cancelButtonType={'button'}
          cancelButtonOnCLick={removePopUpFunction}
          submitButtonName={'Delete'}
          submitButtonType={'button'}
          submitButtonOnCLick={requestDeleteItem}
        />
      </div>
    </div>
  );
}
