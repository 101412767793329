import { request } from './request';
import { HOST_IP } from '../configs/backend';

const ENDPOINTS = {
  addItem: `${HOST_IP}/inventory/add-item`,
  editItem: `${HOST_IP}/inventory/edit-item`,
  deleteItem: `${HOST_IP}/inventory/delete-item`,
  addImage: `${HOST_IP}/inventory/add-image`,
  deleteImage: `${HOST_IP}/inventory/delete-image`,
  addMoreImage: `${HOST_IP}/inventory/add-more-image`,
  deleteMoreImage: `${HOST_IP}/inventory/delete-more-image`,
  updateItemAvailability: `${HOST_IP}/inventory/update-item-availability`
};

export const addItem = (inventoryId, category, item) => {
  return request(ENDPOINTS.addItem, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryId, category, item })
  });
};

export const editItem = (inventoryId, category, itemId, item) => {
  return request(ENDPOINTS.editItem, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryId, category, itemId, item })
  });
};

export const deleteItem = (inventoryId, category, itemId) => {
  return request(ENDPOINTS.deleteItem, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryId, category, itemId })
  });
};

export const addImage = (image, inventoryId, category, itemId) => {
  image.append('inventoryId', inventoryId);
  image.append('category', category);
  image.append('itemId', itemId);
  return request(ENDPOINTS.addImage, {
    method: 'POST',
    body: image
  });
};

export const deleteImage = (inventoryId, category, itemId) => {
  return request(ENDPOINTS.deleteImage, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryId, category, itemId })
  });
};

export const addMoreImage = (image, imageIndex) => {
  image.append('imageIndex', imageIndex);
  return request(ENDPOINTS.addMoreImage, {
    method: 'POST',
    body: image
  });
}

export const deleteMoreImage = (imageIndex) => {
  return request(ENDPOINTS.deleteMoreImage, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ imageIndex })
  });
}

export const updateItemAvailability = (inventoryId, category, itemId, availability) => {
  return request(ENDPOINTS.updateItemAvailability, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ inventoryId, category, itemId, availability })
  });
};