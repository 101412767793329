import { useState, useEffect } from 'react';
import { marked } from 'marked';
import '../../styles/LegalAgreements/LegalAgreement.scss';
import { VentoryLoader } from '../../components';
import {
  getTermsAndConditions as requestGetTermsAndConditions,
  getPrivacyAndPolicy as requestGetPrivacyAndPolicy,
  getReturnAndRefundPolicy as requestGetReturnAndRefundPolicy
} from '../../api';

export default function LegalAgreement(props) {
  const { agreementType } = props;
  const [readmeContent, setReadmeContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchReadme = async () => {
      setIsLoading(true);

      let response;
      switch (agreementType) {
        case 'terms-and-conditions':
          response = await requestGetTermsAndConditions();
          break;
        case 'privacy-and-policy':
          response = await requestGetPrivacyAndPolicy();
          break;
        case 'return-and-refund-policy':
          response = await requestGetReturnAndRefundPolicy();
          break;
        default:
          response = {success: false}
      }
      if (response.success) {
        const markdown = response.data;
        setReadmeContent(marked.parse(markdown));
      }

      setIsLoading(false);
    };
    fetchReadme().then();
  }, []);

  return (
    <div className="legal-agreement">
      {isLoading ? (
        <div className="loader-container">
          <VentoryLoader height="100px" />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: readmeContent }} />
      )}
    </div>
  );
}
