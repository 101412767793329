import { createContext } from 'react';
import {useProvideCanvas } from '../hooks';

export const CanvasContext = createContext({
  loading: true,
  featuredCanvas: {},
  myCanvas: {},
  scheduleCanvasDownloadTask: () => {},
  clearCanvasDownloadTask: () => {},
  downloadCanvasImage: () => {},
  createTableSticker: () => {}
});

export const CanvasProvider = ({ children }) => {
  const canvas = useProvideCanvas();

  return (
    <CanvasContext.Provider
      value={canvas}
    >
      {children}
    </CanvasContext.Provider>
  )
}