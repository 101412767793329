import { Secrets } from '../configs';

// Utility to hash the secret key to create a deterministic IV
async function deriveIvFromSecretKey() {
  const encoder = new TextEncoder();
  const data = encoder.encode(Secrets);
  const hash = await window.crypto.subtle.digest('SHA-256', data); // Hash the secret key
  return new Uint8Array(hash).slice(0, 12); // Use the first 12 bytes for the IV (96 bits)
}

// Function to export a CryptoKey to a JWK format
async function exportKey(key) {
  const exported = await window.crypto.subtle.exportKey('jwk', key);
  return JSON.stringify(exported);
}

// Function to import a CryptoKey from a JWK format
async function importKey(jwk) {
  return await window.crypto.subtle.importKey(
    'jwk',
    jwk,
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );
}

// Function to generate a key
export const generateKey = async () => {
  const key =  await window.crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );

  // convert the key to string and return
  return await exportKey(key)
};

// Function to encrypt the plaintext
export const encryptText = async (plaintext, key) => {
  const encoder = new TextEncoder();
  const encodedText = encoder.encode(plaintext);
  const iv = await deriveIvFromSecretKey();
  key = await importKey(JSON.parse(key));

  try {
    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      encodedText
    );

    const encryptedArray = new Uint8Array(encrypted);
    return btoa(String.fromCharCode(...encryptedArray));
  } catch (error) {return null;}
};

// Function to decrypt the ciphertext
export const decryptText = async (ciphertext, key) => {
  const iv = await deriveIvFromSecretKey();
  const ciphertextArray = new Uint8Array(atob(ciphertext)
    .split("").map(char => char.charCodeAt(0)));
  key = await importKey(JSON.parse(key));

  try {
    const decryptedBuffer = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      key,
      ciphertextArray
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);

  } catch (error) {return null;}
};