import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkHpcgmDxsoV_5B8rxtJkJlJT6xyeK1Pg",
  authDomain: "scanwithventory-82f53.firebaseapp.com",
  projectId: "scanwithventory-82f53",
  storageBucket: "scanwithventory-82f53.appspot.com",
  messagingSenderId: "878943021844",
  appId: "1:878943021844:web:83e23c726e02f34b43f001"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = getAuth(app);

export const FIREBASE_STORAGE_BUCKET_URL = `https://storage.googleapis.com/${firebaseConfig.storageBucket}`;