import '../../styles/Home/CustomerApp.scss';
import { phoneDemo } from '../../assets/home/demos';

export default function CustomerApp() {
  return (
    <div className="customer-app">
      <div className="customer-app-left">
        <div className='head'>
          <p className="title">Customer's App</p>
          <p className='heading'>
            Seamless Experience, Stunning UI: Discover Ventory's Customer App
          </p>
        </div>
        <div className="description">
          <p>
            Empower customers with our mobile web app – your gateway to
            seamless transactions and personalized journeys. Designed for
            partner's end users, this innovative interface redefines engagement
            and elevates convenience.
          </p>
        </div>
      </div>
        <div className="customer-app-right">
          <img src={phoneDemo} alt="app-demo-img"  />
        </div>
    </div>
  );
}