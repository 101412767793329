import { createContext } from 'react';
import { useProvideNotification } from '../hooks';
import Notification from '../components/Notification';

export const NotificationContext = createContext({
  notificationQueue: [],
  queueNotification: () => {},
  removeNotification: () => {},
  setNotificationStatusSuccess: () => {},
  setNotificationStatusFailed: () => {}
});

export const NotificationProvider = ({ children }) => {
  const notification = useProvideNotification();

  return (
    <NotificationContext.Provider
      value={notification}
    >
      {children}
      <Notification />
    </NotificationContext.Provider>
  )
}