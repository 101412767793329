import { createContext } from 'react';
import { useProvideDashboard } from '../hooks';

export const DashboardContext = createContext({
  isLoading: true,
  inventoryList: [],
  inventoryId: '',
  inventory: {},
  changeInventory: () => {},
});

export const DashboardProvider = ({ children }) => {
  const dashboard = useProvideDashboard();

  return (
    <DashboardContext.Provider
      value={dashboard}
    >
      {children}
    </DashboardContext.Provider>
  )
}