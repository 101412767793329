import { request } from './request';
import { HOST_IP } from '../configs/backend';

const ENDPOINTS = {
  createTableSticker: `${HOST_IP}/canvas/create-table-sticker`,
};

export const createTableSticker = (inventoryId, stickerData) => {
  return request(ENDPOINTS.createTableSticker, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        inventoryId,
        stickerData
      })
    }
  );
};