import { useState } from 'react';
import '../../../../styles/Dashboard/ManageInventory/PreviewInventory/PreviewVariants.scss';

export default function PreviewVariants(props) {
  const { variants } = props;
  const [currentlySelectedVariantIndex, setCurrentlySelectedVariantIndex] = useState(0);

  return (
    <div className="preview-variants">
      {variants.length && (
        <p>{variants[currentlySelectedVariantIndex].variantPrice}</p>
      )}

      {variants.length > 1 && (
        <div className="pr-variant-btn">
          {variants.map((variant, index) => (
            <button
              key={`pr-variant-btn-${index}`}
              onClick={() => setCurrentlySelectedVariantIndex(index)}
              style={currentlySelectedVariantIndex === index ? {
                color: 'white',
                backgroundColor: 'seagreen'
              } : {
                color: 'seagreen',
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
              }}
            >
              {(variant.variantName.charAt(0).toUpperCase() + variant.variantName.slice(1).toLowerCase())}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
