import { useNavigate } from 'react-router-dom';
import '../../styles/Home/index.scss';
import { useAuth } from '../../hooks';
import { VentoryLogo } from '../../components'
import Carousel from './Carousel';
import SignIn from './SignIn';
import WhatsNew from './WhatsNew';
import WhyVentory from './WhyVentory';
import WhyChooseUs from './WhyChooseUs';
import PartnersApp from './PartnersApp';
import CustomerApp from './CustomerApp';
import WhatElse from './WhatElse';
import Footer from './Footer';

export default function Home() {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="home">
      <nav className="nav-bar-container">
        <div className="nav-bar">
          <VentoryLogo />

          {auth.user ? (
            <button className="ds-btn" onClick={() => navigate('/dashboard')}>
              <p>Dashboard</p>
            </button>
          ): (
            <button className="su-btn" onClick={() => navigate('/register')}>
              <p>Register</p>
            </button>
          )}
        </div>
      </nav>

      <div className="hero-section">
        <div className="hero-section-inner">
          <div className="left-container">
            <div className="carousel-container">
              <Carousel />
            </div>
          </div>

          <div className="right-container">
            {auth.user
              ? (<WhatsNew />)
              : (<SignIn />)
            }
          </div>
        </div>
      </div>

      <div className="home-container">
        <WhyVentory />
      </div>

      <div className="home-container">
        <WhyChooseUs />
      </div>

      <div className="home-container">
        <PartnersApp />
      </div>

      <div className="home-container">
        <CustomerApp />
      </div>

      <div className="home-container">
        <WhatElse />
      </div>

      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
}
