import '../../../../styles/Dashboard/ManageInventory/PreviewInventory/PreviewDescription.scss';


export default function PreviewDescription(props) {
  const { description } = props;


  return (
    <div className="preview-description">
      <p>{description}</p>
    </div>
  );
}
