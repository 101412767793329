import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/components/CustomInput.scss';

export default function CustomInput(props) {
  const {
    type, value, onChange, placeholder, required, maxLength, icon
  } = props

  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleInputValueOnChange = (event) => {
    const targetValue = event.target.value;
    if (/^(\S+\s?)+\S*$/.test(targetValue) || targetValue === '') {
      onChange(event);
    }
  }

  return (
    <div className="custom-input">
      <div className="label-container">
        <p
          className="label"
          style={{
            animation: value ? 'labelUp forwards' : isInputFocused
              ? 'labelUp 0.2s ease-in-out forwards' : '',
            color: isInputFocused ? 'currentcolor' : ''
          }}
        >{placeholder}</p>
      </div>

      <div
        className="custom-input-container"
        style={{ borderColor: isInputFocused ? 'currentcolor' : '' }}
      >
        <div className="icon-container">
          <FontAwesomeIcon
            className="icon"
            style={{ color: isInputFocused ? 'currentcolor' : '' }}
            icon={icon}
          />
        </div>

        <input
          size={1}
          type={type}
          value={value}
          onChange={handleInputValueOnChange}
          maxLength={maxLength}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          required={required}
        />

        {maxLength && (
          <div className="input-length">
            <p
              style={{ color: isInputFocused ? 'currentcolor' : '' }}
            >{value.length}/{maxLength}</p>
          </div>
        )}
      </div>
    </div>
  );
}
