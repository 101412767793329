import { useNavigate } from 'react-router-dom';
import '../../styles/Home/Carousel.scss';
import carousel_img_1 from "../../assets/home/carousel/c1.png"

export default function Carousel() {
  const navigate = useNavigate();

  return (
    <div className="carousel">
      <div className="carousel-nav-container">
        <div className="carousel-nav">
          <button className="carousel-nav-btn op-btn">
            Our partners
          </button>

          {/*separator*/}
          <div className="sep"></div>

          <button
            className="carousel-nav-btn pnp-btn"
            onClick={() => navigate('/plans-and-pricing')}
          >
            Plans and pricing
          </button>

          {/*separator*/}
          <div className="sep"></div>

          <button className="carousel-nav-btn htu-btn">
            How to use?
          </button>

          {/*separator*/}
          <div className="sep"></div>

          <button className="carousel-nav-btn ft-btn">
            Features
          </button>
        </div>
      </div>

      <div className="carousel-images-container">
        <div className="carousel-images">
          <img
            className="carousel-image"
            src={carousel_img_1}
            alt="india_78"
          />
        </div>
      </div>
    </div>
  );
}
