import { createContext } from 'react';
import { useProvideAuth } from '../hooks';

export const AuthContext = createContext({
  user: null,
  inventoryId: '',
  setInventoryId: () => {},
  loading: true,
  changeReCaptchaContainer: () => {},
  removeReCaptchaContainer: () => {},
  setUserAndTokens: () => {},
  handleEmailIdServerVerification: () => {},
  handleCheckEmailIdExists: () => {},
  handleMobileNumberServerVerification: () => {},
  handleCheckMobileNumberExists: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider
      value={auth}
    >
      {children}
    </AuthContext.Provider>
  )
}