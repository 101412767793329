import { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/components/CustomTextArea.scss';

export default function CustomTextArea(props) {
  const { value, onChange, placeholder, required, maxLength, icon } = props;

  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const [textAreaContainerHeight, setTextAreaContainerHeight] = useState(0);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setTextAreaContainerHeight(entry.contentRect.height);
      }
    });
    if (container) {resizeObserver.observe(container);}

    return () => {
      if (container) {resizeObserver.unobserve(container);}
      resizeObserver.disconnect();
    };
  }, [])

  const handleInputValueOnChange = (event) => {
    const targetValue = event.target.value;
    if (/^(\S+\s?)+\S*$/.test(targetValue) || targetValue === '') {
      onChange(event);
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = Math.min(
        textareaRef.current.scrollHeight, (textAreaContainerHeight - 10)
      ) + 'px';
    }
  }

  return (
    <div className="custom-textarea">
      <div className="label-container">
        <p
          className="label"
          style={{
            animation: value ? 'labelUp forwards'
              : isTextAreaFocused ? 'labelUp 0.2s ease-out forwards' : '',
            top: `${(textAreaContainerHeight/2) - 2}px`,
            color: isTextAreaFocused ? 'currentcolor' : ''
          }}
        >{placeholder}</p>
      </div>

      <div
        ref={containerRef}
        className="custom-textarea-container"
        style={{ borderColor: isTextAreaFocused ? 'currentcolor' : '' }}
      >
        <div className="icon-container">
          <FontAwesomeIcon
            className="icon"
            icon={icon}
            style={{ color: isTextAreaFocused ? 'currentcolor' : '' }}
          />
        </div>

        <textarea
          ref={textareaRef}
          value={value}
          onChange={handleInputValueOnChange}
          maxLength={maxLength}
          spellCheck="true"
          onFocus={() => setIsTextAreaFocused(true)}
          onBlur={() => setIsTextAreaFocused(false)}
          required={required}
          rows="1"
        ></textarea>

        {maxLength && (
          <div className="input-length">
            <p
              style={{ color: isTextAreaFocused ? 'currentcolor' : '' }}
            >{value.length}/{maxLength}</p>
          </div>
        )}
      </div>
    </div>
  );
}
