import { useEffect, useState } from 'react';
import '../../../../styles/Dashboard/ManageInventory/AddEditItem/SelectCategoryPanel.scss';
import { ActionButton } from '../../../../components';
import { useManageInventory } from '../../../../hooks';
import { searchKeywordInArray } from '../../../../utils'

export default function SelectCategoryPanel(props) {
  const { removePopUpFunction, itemCategory, setItemCategory } = props
  const manageInventory = useManageInventory();
  const { itemCategoriesList } = manageInventory;
  const [categoryInput, setCategoryInput] = useState('');
  const [availableCategories, setAvailableCategories] = useState([]);

  useEffect(() => {
    setCategoryInput(itemCategory);
  }, [itemCategory]);

  useEffect(() => {
    setAvailableCategories(itemCategoriesList);
  }, [itemCategoriesList]);

  const handleCategoryValueChange = (event) => {
    const trimmedValue = event.target.value.replace(/^\s+/g, '');
    setCategoryInput(trimmedValue.toUpperCase());
  }

  const handleCategorySubmit = (event) => {
    event.preventDefault();
    // set the new category
    setItemCategory(categoryInput.toUpperCase().trim());
    // remove the pop
    removePopUpFunction();
  }

  const handleExistingCategoryButtonCLick = (category) => {
    // set the existing category
    setItemCategory(category);
    // remove the pop
    removePopUpFunction();
  }

  const handleCategorySearch = (event) => {
    const keyword = event.target.value;
    setAvailableCategories(searchKeywordInArray(itemCategoriesList, keyword));
  }

  return (
    <div className="select-category-panel">
      <form className="add-new-category-form">
        <input
          type="text"
          value={categoryInput}
          onChange={handleCategoryValueChange}
          placeholder="Add New Category"
          maxLength={30}
        />

        <div className="safe-exit">
          <ActionButton
            cancelButtonName={'Cancel'}
            cancelButtonType={'button'}
            cancelButtonOnCLick={removePopUpFunction}
            submitButtonName={'Add'}
            submitButtonType={'submit'}
            submitButtonOnCLick={handleCategorySubmit}
          />
        </div>
      </form>

      <div className="choose-category">
        <input
          type="text"
          onChange={handleCategorySearch}
          placeholder="Search"
        />

        <div className="available-category-container">
          {availableCategories.map((category, index) => (
            <button
              key={`category-${index}`}
              className="cat-btn"
              onClick={() => handleExistingCategoryButtonCLick(category)}
            >{category}</button>
          ))}
        </div>
      </div>
    </div>
  )
}
