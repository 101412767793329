import React, { useEffect, useRef } from 'react';
import QRCodeStyling from "qr-code-styling";
import '../../../../../styles/Dashboard/Home/Canvas/TableSticker/Sticker.scss';

export default function Sticker(props) {
  const { inventoryUrl, stickerData } = props;
  const qrCodeRef = useRef(null);

  useEffect(() => {
    // remove existing QR code from dom
    document.querySelector('.qr').innerHTML = '';

    // create the new QR code
    const qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      type: 'png',
      dotsOptions: {
        color: stickerData.qrColor,
        type: "rounded"
      },
      cornersSquareOptions: {
        "type": "extra-rounded"
      },
      cornersDotOptions: {
        "type": "dot"
      },
      data: inventoryUrl
    });

    // add the QR code to dom
    qrCode.append(qrCodeRef.current);
  }, [stickerData]);

  return (
    <div
      className="sticker"
      style={{
        background: `linear-gradient(to bottom right, 
        ${stickerData.bgColor1}, ${stickerData.bgColor2})`
      }}
    >
      <div className="sticker-inner">
        <div
          className="qr"
          ref={qrCodeRef}
        ></div>

        <p className="scan-text">Scan to see the menu</p>

        <div
          className="shop-name"
          style={{background: stickerData.snBgColor}}
        >
          <p
            style={{color: stickerData.snColor}}
          >
            {stickerData.shopName.toUpperCase()}
          </p>
        </div>
      </div>
    </div>
  );
}