import { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretRight, faCircle, faCircleArrowDown
} from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/Dashboard/ManageInventory/AddEditItem/index.scss';
import { useManageInventory } from '../../../../hooks';
import { PopUp, ActionButton } from '../../../../components'
// import input field components
import SelectCategoryPanel from './SelectCategoryPanel';
import SelectImagesPanel from './SelectImagesPanel';
import SelectVariantsPanel from './SelectVariantsPanel';
import SelectTagsPanel from './SelectTagsPanel';
import UploadItemPanel from './UploadItemPanel';

export default function AddEditItem() {
  const manageInventory = useManageInventory();
  const {
    itemCategory, setItemCategory,
    itemName, setItemName,
    itemDescription, setItemDescription,
    itemImages, setItemImages,
    itemVariants, setItemVariants,
    itemTags, setItemTags,
    operationType, resetInput
  } = manageInventory;

  // toggle buttons, focus & blurs
  const [isItemNameInputFocused, setIsItemNameInputFocused] = useState(false);
  const [isItemDescriptionInputFocused, setIsItemDescriptionInputFocused] = useState(false);
  const [categoryButtonState, setCategoryButtonState] = useState(!itemCategory);
  const [uploadItemButtonState, setUploadItemButtonState] = useState(false);
  const [isItemInputInnerContainerOverflow, setIsItemInputInnerContainerOverflow] = useState(false);
  const [itemInputContainerScrollDegree, setItemInputContainerScrollDegree] = useState(0);
  const itemInputContainerRef = useRef(null);
  const itemInputInnerContainerRef = useRef(null);

  useEffect(() => {
    const checkSize = () => {
      const divElement = itemInputInnerContainerRef.current;
      if (divElement) {
        const divHeight  = divElement.offsetHeight;
        const parentHeight = divElement.parentNode.offsetHeight;
        setIsItemInputInnerContainerOverflow(divHeight > parentHeight);
      }
    };
    const resizeObserver = new ResizeObserver(checkSize);

    const checkScroll = () => {
      const childDiv = itemInputContainerRef.current;
      if (childDiv) {
        setItemInputContainerScrollDegree(
          (childDiv.scrollTop/(childDiv.scrollHeight - childDiv.clientHeight))*180
        );
      }
    };

    if (itemInputContainerRef.current) {
      resizeObserver.observe(itemInputContainerRef.current);
      itemInputContainerRef.current.addEventListener('scroll', checkScroll);
    }

    return () => {
      if (itemInputContainerRef.current) {
        resizeObserver.unobserve(itemInputContainerRef.current);
        itemInputContainerRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, []);

  // toggle buttons
  const toggleSelectCategoryButtonClick = () => setCategoryButtonState(!categoryButtonState);
  const toggleUploadItemButtonClick = () =>  setUploadItemButtonState(!uploadItemButtonState);

  // handle item name value change
  const handleItemNameChange = (event) => {
    const targetValue = event.target.value;
    if (/^(\S+\s?)+\S*$/.test(targetValue) || targetValue === '') {
      setItemName(targetValue);
    }
  }

  // handle item description value change
  const handleDescriptionValueChange = (event) => {
    setItemDescription(event.target.value);
  }

  // Scroll to the bottom of the item input container div
  const toggleScroll = () => {
    if (itemInputContainerRef.current) {
      itemInputContainerRef.current.scrollTop = (itemInputContainerScrollDegree >= 90) ? 0
      : itemInputContainerRef.current.scrollTop = itemInputContainerRef.current.scrollHeight;
    }
  };

  return (
    <div className="add-edit-item">
      {/*Heading*/}
      <div className="heading-container">
        <h1 className="heading">
          {operationType ? 'Add Item' : 'Edit Item'}
        </h1>
      </div>

      <div className="item-input-container" ref={itemInputContainerRef}>
        <div className="inner-container" ref={itemInputInnerContainerRef}>
          {/*Item-Name*/}
          <div
            className="item-name-input-container"
            style={{backgroundColor: itemName ? '#f7fcfa' :
                isItemNameInputFocused ? '#f7fcfa' : null }}
          >
            <div
              className="indicator"
              style={{ backgroundColor: itemName ? '#39ac6c' :
                isItemNameInputFocused ? '#39ac6c' : null
              }}
            ></div>

            <input
              className="item-name-input"
              type="text"
              value={itemName}
              onChange={handleItemNameChange}
              maxLength={30}
              placeholder="Item Name"
              onFocus={() => setIsItemNameInputFocused(true)}
              onBlur={() => setIsItemNameInputFocused(false)}
            />
          </div>

          {/*Item-Category*/}
          <div className="select-item-category-container">
            <div
              className="select-btn"
              onClick={toggleSelectCategoryButtonClick}
            >
              <FontAwesomeIcon
                className="icon"
                style={{
                  color: itemCategory ? '#39ac6c' : null,
                  transform: categoryButtonState ? 'rotate(90deg)' : null
                }}
                icon={faCaretRight}
              />
              <p className="btn-label">
                {itemCategory ? itemCategory : 'Select Category'}
              </p>
            </div>

            {categoryButtonState && (
              <div className="select-item-category-panel">
                <SelectCategoryPanel
                  removePopUpFunction={toggleSelectCategoryButtonClick}
                  itemCategory={itemCategory}
                  setItemCategory={setItemCategory}
                />
              </div>
            )}
          </div>

          {/*Item-Image*/}
          <div className="select-item-image-container">
            <SelectImagesPanel
              itemImages={itemImages}
              setItemImages={setItemImages}
            />
          </div>

          {/*Item-Description*/}
          <div
            className="item-description-input-container"
            style={{ backgroundColor: itemDescription ? '#f7fcfa' :
                isItemDescriptionInputFocused ? '#f7fcfa' : null
            }}
          >
            <div
              className="indicator"
              style={{ backgroundColor: itemDescription ? '#39ac6c' :
                  isItemDescriptionInputFocused ? '#39ac6c' : null
              }}
            ></div>

            <textarea
              className="item-description-input"
              value={itemDescription}
              onChange={handleDescriptionValueChange}
              maxLength={500}
              placeholder="About Item Description and Contents"
              onFocus={() => setIsItemDescriptionInputFocused(true)}
              onBlur={() => setIsItemDescriptionInputFocused(false)}
            />
          </div>

          {/*Item-Variants*/}
          <div className="select-item-variants-container">
            <div className="select-btn">
              <FontAwesomeIcon
                className="icon"
                style={{ color: (itemVariants.length > 1) ? '#39ac6c' : null }}
                icon={faCircle}
              />
              <p className="btn-label">
                {(itemVariants.length > 1) ?
                  'Edit Variants & Prices' : 'Add Variants & Prices'}
              </p>
            </div>

            <div className="select-item-variants-panel">
              <SelectVariantsPanel
                variants={itemVariants}
                setVariants={setItemVariants}
              />
            </div>
          </div>

          {/*Item-Tags*/}
          <div className="select-item-tags-container">
            <div className="select-btn">
              <FontAwesomeIcon
                className="icon"
                style={{ color: itemTags.length ? '#39ac6c' : null }}
                icon={faCircle}
              />
              <p className="btn-label">
                {itemTags.length ? 'Edit Tags' : 'Add Tags'}
              </p>
            </div>

            <div className="select-item-tags-panel">
              <SelectTagsPanel
                tags={itemTags}
                setTags={setItemTags}
              />
            </div>
          </div>
        </div>
      </div>

      {/*Upload-Item*/}
      <div className="upload-item-btn-container">
        <ActionButton
          cancelButtonName={'Reset All'}
          cancelButtonType={'button'}
          cancelButtonOnCLick={resetInput}
          submitButtonName={'Add Item'}
          submitButtonType={'submit'}
          submitButtonOnCLick={toggleUploadItemButtonClick}
        />
      </div>

      {uploadItemButtonState && (
        <PopUp>
          <UploadItemPanel removePopUpFunction={toggleUploadItemButtonClick} />
        </PopUp>
      )}

      {isItemInputInnerContainerOverflow && (
        <div className="scroll-helper">
          <FontAwesomeIcon
            className="icon"
            style={{
              transform: `rotate(-${itemInputContainerScrollDegree}deg)`
            }}
            icon={faCircleArrowDown}
            onClick={toggleScroll}
          />
        </div>
      )}
    </div>
  );
}
