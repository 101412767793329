import { Link } from 'react-router-dom';
import '../../styles/Home/SignIn.scss';
import { useAuth } from '../../hooks';
import { CredVerifier, GoogleVerifier } from '../../components';

export default function SignIn() {
  const auth = useAuth();
  const {
    handleEmailIdServerVerification,
    handleCheckEmailIdExists,
    handleCheckMobileNumberExists,
    handleMobileNumberServerVerification
  } = auth;

  return (
    <div className="sign-in">
      <div className="sign-in-container">
        {/* sign-in with email/mobile */}
        <CredVerifier
          onVerifyEmailIdClick={handleCheckEmailIdExists}
          onEmailIdTokenChange={handleEmailIdServerVerification}
          onVerifyMobileNumberClick={handleCheckMobileNumberExists}
          onMobileNumberTokenChange={handleMobileNumberServerVerification}
        />

        {/* sign-in with Google */}
        <div className="signup-with-google">
          <p>OR,</p>
          <GoogleVerifier
            onGoogleTokenChange={handleEmailIdServerVerification}
            authType="sign-in"
          />
          <p className="sign-in-text">
            Don't have a ventory account?
            <Link to="/register">Register</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
