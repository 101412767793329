import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import '../../../styles/Dashboard/ManageInventory/index.scss';
import AddEditItem from './AddEditItem';
import PreviewInventory from './PreviewInventory';
import logo from '../../../assets/shared/logo.png';

export default function ManageInventory() {
  const navigate = useNavigate();
  // windows visibility status
  const [addEditItemWindowStatus, setAddEditItemWindowStatus] = useState(true);
  const [previewWindowStatus, setPreviewWindowStatus] = useState(true);

  // media queries
  const isMobileDevice = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    if (!isMobileDevice) {
      setAddEditItemWindowStatus(true);
      setPreviewWindowStatus(true);
    } else {
      setAddEditItemWindowStatus(true);
      setPreviewWindowStatus(false);
    }
  }, [isMobileDevice]);

  const toggleManageInventoryWindow = () => {
    if (isMobileDevice) {
      setAddEditItemWindowStatus(!addEditItemWindowStatus);
      setPreviewWindowStatus(!previewWindowStatus);
    }
  }

  return (
    <div className="manage-inventory">
      {isMobileDevice && (
        <div className="toggle-window-container">
          <div
            className="logo-container"
            onClick={() => navigate('/')}
          >
            <img src={logo} alt='logo'/>
          </div>

          <div
            className="toggle-window"
            onClick={toggleManageInventoryWindow}
          >
            <button
              className="toggle-window-btn"
              style={addEditItemWindowStatus ? {
                backgroundColor: 'dodgerblue',
                color: 'white' } : null}
            >Add Item</button>
            <button
              className="toggle-window-btn"
              style={previewWindowStatus ? {
                backgroundColor: 'dodgerblue',
                color: 'white' } : null}
            >Preview Items</button>
          </div>
        </div>
      )}

      <div className="manage-inventory-container">
        {addEditItemWindowStatus && (
          <div className="add-edit-item-container">
            <AddEditItem
              toggleManageInventoryWindow={toggleManageInventoryWindow}
              isMobileDevice={isMobileDevice}
            />
          </div>
        )}

        {previewWindowStatus && (
          <div className="preview-container">
            <PreviewInventory
              toggleManageInventoryWindow={toggleManageInventoryWindow}
              isMobileDevice={isMobileDevice}
            />
          </div>
        )}
      </div>
    </div>
  );
}
