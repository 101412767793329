import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStore, faHouse, faPenToSquare, faReceipt,
  faChartLine, faListCheck, faPercent
} from '@fortawesome/free-solid-svg-icons';
import '../../../styles/Dashboard/SideBar/SelectDashboardOption.scss';

export default function SelectDashboardOption() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedDashboardOption, setSelectedDashboardOption] = useState({
    home: false,
    manageInventory: false,
    manageOffers: false,
    customerBilling: false,
    manageOrders: false,
    analytics: false,
    manageProfile: false
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let choices = { ...selectedDashboardOption }
    // turn off all the options in the dashboard
    Object.keys(choices).forEach((key) => {
      choices[key] = false;
    });
    let choice;
    switch (queryParams.get('v')) {
      case 'manage-inventory':
        choice = 'manageInventory'; break;
      case 'manage-offers':
        choice = 'manageOffers'; break;
      case 'customer-billing':
        choice = 'customerBilling'; break;
      case 'manage-orders':
        choice = 'manageOrders'; break;
      case 'analytics':
        choice = 'analytics'; break;
      case 'manage-profile':
        choice = 'manageProfile'; break;
      default:
        choice = 'home'; break;
    }
    // turn on only the selected option in the dashboard
    setSelectedDashboardOption({
      ...choices,
      [choice]: true
    });
  }, [location.search]);

  return (
    <div className="select-dashboard-option">
      <button
        className={selectedDashboardOption.home ? 'selected' : ''}
        onClick={() => navigate('/dashboard?v=home', { replace: true })}
      >
        <FontAwesomeIcon className="icon" icon={faHouse} />
        <p>Home</p>
      </button>

      <button
        className={selectedDashboardOption.manageInventory ? 'selected' : ''}
        onClick={
          () => navigate('/dashboard?v=manage-inventory', { replace: true })
        }
      >
        <FontAwesomeIcon className="icon" icon={faPenToSquare} />
        <p>Manage Inventory</p>
      </button>

      <button
        className={selectedDashboardOption.manageOffers ? 'selected' : ''}
        onClick={
          () => navigate('/dashboard?v=manage-offers', { replace: true })
        }
      >
        <FontAwesomeIcon className="icon" icon={faPercent} />
        <p>Manage Offers</p>
      </button>

      <button
        className={selectedDashboardOption.customerBilling ? 'selected' : ''}
        onClick={
          () => navigate('/dashboard?v=customer-billing', { replace: true })
        }
      >
        <FontAwesomeIcon className="icon" icon={faReceipt} />
        <p>Customer Billing</p>
      </button>

      <button
        className={selectedDashboardOption.manageOrders ? 'selected' : ''}
        onClick={
          () => navigate('/dashboard?v=manage-orders', { replace: true })
        }
      >
        <FontAwesomeIcon className="icon" icon={faListCheck} />
        <p>Manage Orders</p>
      </button>

      <button
        className={selectedDashboardOption.analytics ? 'selected' : ''}
        onClick={() => navigate('/dashboard?v=analytics', { replace: true })}
      >
        <FontAwesomeIcon className="icon" icon={faChartLine} />
        <p>Analytics</p>
      </button>

      <button
        className={selectedDashboardOption.manageProfile ? 'selected' : ''}
        onClick={
          () => navigate('/dashboard?v=manage-profile', { replace: true })
        }
      >
        <FontAwesomeIcon className="icon" icon={faStore} />
        <p>Manage Profile</p>
      </button>
    </div>
  );
}