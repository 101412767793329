import '../../styles/Home/WhyVentory.scss';
import { HomeDemo } from '../../assets/home/demos';

export default function WhyVentory() {
  return (
    <div className="why-ventory">
      <div className='why-ventory-left'>
        <div className='head'>
          <p className="title">Why Ventory ?</p>
          <p className='heading'>Because we care about your business.</p>
        </div>
        <div className='description'>
          <p>
            At Ventory, we go beyond conventional inventory
            management, crafting an experience that transcends expectations.
            Embrace a future where precision, innovation, and efficiency
            converge to propel your business to new heights with Ventory's
            advanced solutions.
          </p>
        </div>
      </div>
      <div className='why-ventory-right'>
        <img src={HomeDemo} alt='Venotry Image' />
      </div>
    </div>
  );
}