import '../../styles/PlansAndPricing/index.scss';
import { VentoryLogo, SelectPlans } from '../../components';

export default function PlansAndPricing() {
  return (
    <div className="plans-and-pricing">
      <nav className="nav">
        <VentoryLogo />
      </nav>

      <div className="main-container">
        <div className="main">
          <div className="heading">
            <p>Plans to <b>POWER</b> ⚡ your business.</p>
          </div>

          <div className="select-plans-container">
            <SelectPlans
              onPlanSelect={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}