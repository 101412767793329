import { createContext } from 'react';
import { useProvideManageInventory } from '../hooks';

export const ManageInventoryContext = createContext({
  itemCategory: '',
  setItemCategory: () => {},
  itemName: '',
  setItemName: () => {},
  itemDescription: '',
  setItemDescription: () => {},
  itemImages: [],
  setItemImages: () => {},
  itemVariants: [],
  setItemVariants: () => {},
  itemTags: [],
  setItemTags: () => {},
  operationType: '',
  getSanitizedItemCategory: () => {},
  getSanitizedItemName: () => {},
  getSanitizedItemDescription: () => {},
  getSanitizedItemVariants: () => {},
  getSanitizedItemTags: () => {},
  resetInput: () => {},
  setOperationTypeToAddItem: () => {},
  setOperationTypeToEditItem: () => {},
  uploadNewItem: () => {},
  uploadEditedItem: () => {},
  deleteItem: () => {},
  uploadItemImage: () => {},
  deleteItemImage: () => {},
  setItemAvailability: () => {},
  itemCategoriesList: [],
  itemPricesList: [],
  itemTagsList: []
});

export const ManageInventoryProvider = ({ children }) => {
  const manageInventory = useProvideManageInventory();

  return (
    <ManageInventoryContext.Provider
      value={manageInventory}
    >
      {children}
    </ManageInventoryContext.Provider>
  )
}