import '../../../styles/Dashboard/ManageOffers/index.scss';
import manage_offers_coming_soon from '../../../assets/dashboard/manage-offers-cs.svg'

export default function ManageOffers() {
  return (
    <div className="manage-offers">
      <main>
        <img src={manage_offers_coming_soon} alt=''/>
      </main>
    </div>
  );
}
